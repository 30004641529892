<script lang="ts">
  import { secondsTicker, timezone } from "@/stores";
  import { _ } from "svelte-i18n";

  import { client, mutateConfig } from "@/hmi";
  import { dateNow } from "@/utils";

  import Button from "@/lib/Button.svelte";
  import DateTimeInput from "@/lib/DateTimeInput.svelte";
  import GridListView from "@/ui/GridListView.svelte";
  import ConfigDialog from "./ConfigDialog.svelte";

  let date = new Date(dateNow());
  let selectedContinent: string | undefined = undefined;
  let selectedCity: string | undefined = undefined;
  let dateTimeChanging = false;

  $: selectTZ = selectedContinent && selectedCity ? `${selectedContinent}/${selectedCity}` : undefined;
  $: {
    $secondsTicker;
    if (!dateTimeChanging) date = new Date(dateNow());
  }

  function splitTZ(tz: string): string[] | undefined {
    let n = tz.indexOf("/");
    if (n < 0) return [undefined, undefined];
    return [tz.slice(0, n), tz.slice(n + 1)];
  }

  let timezones = new Map<string, string[]>();
  for (let tz of Intl.supportedValuesOf("timeZone")) {
    let [continent, city] = splitTZ(tz);
    if (!continent || !city) continue;
    let cities = timezones.get(continent);
    if (!cities) {
      cities = [];
      timezones.set(continent, cities);
    }
    cities.push(city);
  }
  for (let cities of timezones.values()) {
    cities.sort();
  }

  // Hack to get the select box showing the selected timezone
  setTimeout(() => {
    if ($timezone) {
      let [continent, city] = splitTZ($timezone);
      selectedContinent = continent;
      selectedCity = city;
    }
  }, 50);

  function onSet() {
    if (selectTZ && selectTZ !== $timezone) {
      mutateConfig((config) => {
        config.system.tz = selectTZ!;
      });
      $timezone = selectTZ!;
      console.log("Set timezone to", selectTZ);
    }
  }

  function onSetDateTime() {
    client
      .invoke("SetDateTime", Math.floor(date.getTime() / 1000))
      .catch((e) => alert(e))
      .finally(() => {
        dateTimeChanging = false;
      });
  }
</script>

<ConfigDialog title={$_("menu.datetime")}>
  <div class="content">
    <div class="left">
      <p class="timezone">
        <GridListView
          items={Array.from(timezones.keys()).sort()}
          bind:selected={selectedContinent}
          on:select={() => (selectedCity = undefined)}
          rowSelected={(item) => item === selectedContinent}
        >
          <svelte:fragment let:item>
            {item}
          </svelte:fragment>
        </GridListView>

        <GridListView
          items={(timezones.get(selectedContinent) || []).sort()}
          bind:selected={selectedCity}
          rowSelected={(item) => item === selectedCity}
        >
          <svelte:fragment let:item>
            {item}
          </svelte:fragment>
        </GridListView>
      </p>
      <p class="button-group">
        <Button on:click={onSet} disabled={selectTZ === $timezone || !selectTZ}>SET</Button>
      </p>
    </div>
    <div class="right">
      <div class="active-timezone">Aktive tidszone: {$timezone}</div>
      <DateTimeInput bind:date bind:changing={dateTimeChanging} />
      <br />
      <Button on:click={onSetDateTime} disabled={!dateTimeChanging}>SET</Button>
    </div>
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .content {
    font-family: hmiFont;
    font-size: 2.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    justify-items: center;
    align-items: center;
    padding: $dialog-padding $dialog-padding 40px $dialog-padding;
    overflow: hidden;
    gap: $dialog-padding;
    height: 100%;
  }

  .left {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .timezone {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    height: 45rem;
    max-height: 45rem;
  }

  .active-timezone {
    position: absolute;
    font-size: 2rem;
    top: $dialog-padding;
    &::first-letter {
      text-transform: capitalize;
    }
  }
</style>
