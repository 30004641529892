<script lang="ts">
  export let text: string;
  export let active: boolean = undefined;
  export let disabled: boolean = undefined;
</script>

<div class="status-button" class:active class:disabled>
  <button class="turboheat" on:click><slot /></button>
  <div>{text}</div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .status-button {
    text-align: center;
    color: $primary-dimmed;
    margin-top: 0.32rem;
    font-family: hmiFont;

    > * {
      transition:
        color ease var(--animation-duration),
        border-color ease var(--animation-duration);
    }

    :last-child {
      margin-top: 0.55rem;
      text-transform: capitalize;
    }
    &.active {
      --color: #{$primary}; // Set SVG icon color
      button {
        border-color: $company !important;
      }
      :last-child {
        color: $company;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  button {
    color: $primary-dimmed;
    font-size: 6rem;
    border: 0.5rem solid $primary-dimmed;
    border-radius: 50%;
    background-color: transparent;
    width: 6.45rem; // Must match visual width of Countdown svg circle
    height: 6.45rem; // Must match visual height of Countdown svg circle
    &:active {
      background-color: lighten($background, 7%);
    }
  }
</style>
