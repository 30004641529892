<script lang="ts">
  import { AutoStartFunc } from "@/constants";
  import hmi from "@/hmi";
  import { dateFmt, secondsTicker, timeFmt } from "@/stores";
  import { upperCase } from "lodash-es";
  import { _ } from "svelte-i18n";

  let willTriggerSoon = false;
  let status = hmi.getObjectStore<DTO.AutoStartStatus>("AutoStartStatus");

  $: {
    if ($status) {
      willTriggerSoon = $status.time - $secondsTicker < 60;
    } else {
      willTriggerSoon = false;
    }
  }

  function funcString(func: number) {
    switch (func) {
      case AutoStartFunc.Standby:
        return $_("Standby");
      case AutoStartFunc.Proofing:
        return $_("Proofing");
      case AutoStartFunc.Storage:
        return $_("Storage");
      case AutoStartFunc.Auto:
        return $_("Auto");
    }
    return "";
  }
</script>

{#if $status}
  <div class="info-panel">
    <span class:blink={willTriggerSoon}>{upperCase($_("autostart"))}</span>
    <br />
    {$dateFmt($status.time * 1000)}
    {$timeFmt($status.time * 1000, { seconds: false })}
    {#if $status.dstAdjusted}
      <span class="dst-warning">±1h</span>
    {/if}
    <br />
    {funcString($status.func)}
    {#if $status.func !== AutoStartFunc.Standby}
      {$status.program}
    {/if}
  </div>
{/if}

<style lang="scss">
  .info-panel {
    font-family: hmiFont;
    color: #aaa;
    font-size: 3rem;
    line-height: 1.01;
  }
  .dst-warning {
    font-size: 1.5rem;
    color: hsl(39, 50%, 50%);
  }
</style>
