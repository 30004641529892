<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { _, locale, locales } from "svelte-i18n";
  import { fade, fly } from "svelte/transition";

  import { slowClick } from "@/actions";
  import hmi, { config, equipment, Modes, mutateConfig, programs, state } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import { proofingProgram, showDebugConsole, storageProgram, userLocked } from "@/stores";
  import DebugConsoleIcon from "@/svg/DebugConsoleIcon.svelte";
  import { animationDuration, eventBus, Events, measureText } from "@/utils";

  import CellSignalIcon from "@/svg/CellSignalIcon.svelte";
  import FlyoutProgramItem from "./FlyoutProgramItem.svelte";
  import "./menu.scss";
  import MenuItem from "./MenuItem.svelte";

  export let mode: number | null;
  export let locked: boolean;

  const AUTO_SELECT_COUNTDOWN = 9;

  const dispatch = createEventDispatcher();

  onMount(() => {
    eventBus.on(Events.inputIdle, closeAllFlyoutsAndMenus);
    return () => {
      eventBus.detach(Events.inputIdle, closeAllFlyoutsAndMenus);
    };
  });

  function menuTransition(node: Element) {
    return fly(node, { x: 150, duration: animationDuration });
  }

  let menuRef: HTMLElement;
  const s_light = hmi.getValueStore("set.LIGHT");
  const enableDebugConsole = false;
  const modemStatus = hmi.getObjectStore<DTO.ModemStatus>("ModemStatus");

  let mainOpen = false;
  let programsOpen = false;
  let programsAutoOpen = false;
  let userOpen = false;
  let userAlarmsOpen = false;
  let infoOpen = false;
  let systemOpen = false;
  let systemConfiguratorOpen = false;
  let systemDebugOpen = false;
  let systemServiceOpen = false;

  let storageFlyoutOpen = false;
  let autoFlyoutOpen = false;
  let proofingFlyoutOpen = false;

  let programSelectCountdown = AUTO_SELECT_COUNTDOWN;
  let countdownTimer: number | null = null;
  let menuButtonHeight = 0;
  let isDevMode = localStorage.getItem("devMode") === "true";

  $: if (countdownTimer && programSelectCountdown <= 0) {
    clearTimeout(countdownTimer);
    countdownTimer = null;
  }

  $: anyMenusOpen =
    mainOpen ||
    programsOpen ||
    programsAutoOpen ||
    userOpen ||
    userAlarmsOpen ||
    storageFlyoutOpen ||
    proofingFlyoutOpen ||
    autoFlyoutOpen ||
    infoOpen ||
    systemOpen ||
    systemConfiguratorOpen ||
    systemDebugOpen ||
    systemServiceOpen;

  $: anyFlyoutsOpen = storageFlyoutOpen || autoFlyoutOpen || proofingFlyoutOpen;
  $: hasAuto = $config?.system?.accessiblePrograms?.includes("AUTO");
  $: hasAutoStart = $config?.system?.accessiblePrograms?.includes("AUTO_START");
  $: storagePrograms = $programs?.storage?.programs?.filter((x) => x.use_in_manual || !hasAuto) || [];
  $: proofingPrograms = $programs?.proofing?.programs?.filter((x) => x.use_in_manual || !hasAuto) || [];
  $: showAuto = $programs?.auto?.programs?.length > 0 && hasAuto;
  $: showStorage = storagePrograms.length > 0 && $config?.system?.accessiblePrograms.some((x) => x.startsWith("STORAGE"));
  $: showProofing = proofingPrograms.length > 0 && $config?.system?.accessiblePrograms.some((x) => x.startsWith("PROOFING"));
  $: defrostAvailable = $config?.system?.accessiblePrograms.some(
    (x) => x.endsWith("_DC") || x.endsWith("_KF") || x.endsWith("_SF") || x.endsWith("_HDC")
  );

  function closeSubMenus() {
    programsOpen = false;
    programsAutoOpen = false;
    userOpen = false;
    userAlarmsOpen = false;
    infoOpen = false;
    systemOpen = false;
    systemConfiguratorOpen = false;
    systemDebugOpen = false;
    systemServiceOpen = false;
  }

  function closeFlyouts() {
    storageFlyoutOpen = false;
    autoFlyoutOpen = false;
    proofingFlyoutOpen = false;
    programSelectCountdown = 0;
  }

  export function closeAllFlyoutsAndMenus() {
    mainOpen = false;
    closeFlyouts();
    closeSubMenus();
  }

  function getClickId(e: MouseEvent): string | null {
    let target = e.target as HTMLElement;
    if (target.tagName != "BUTTON") return null;
    return target.getAttribute("data-id");
  }

  $: mode, closeFlyouts(); // React to possible external mode changes

  function onMenuClick(e: MouseEvent) {
    programSelectCountdown = 0;

    let id = getClickId(e);
    if (!id) return;

    if (id !== "auto") autoFlyoutOpen = false;
    if (id !== "storage") storageFlyoutOpen = false;
    if (id !== "proofing") proofingFlyoutOpen = false;

    switch (id) {
      case "menu":
        let open = !mainOpen;
        if (!open) closeAllFlyoutsAndMenus();
        else menuRef.focus();
        // Hack to prevent the menu from closing immediately after opening (due to the menu overlay being clicked for some reason!)
        setTimeout(() => (mainOpen = open), 10);
        return;
      case "light":
        s_light.update((v) => (v !== 0 ? 0 : 1));
        return;
      case "auto":
        if (mode === Modes.AUTO) autoFlyoutOpen = !autoFlyoutOpen;
        break;
      case "storage":
        storageFlyoutOpen = storagePrograms?.length > 1 && !storageFlyoutOpen;
        break;
      case "proofing":
        proofingFlyoutOpen = proofingPrograms?.length > 1 && !proofingFlyoutOpen;
        break;
    }

    let newMode = (() => {
      switch (id) {
        case "auto":
          return Modes.AUTO;
        case "storage":
          return Modes.STORAGE;
        case "proofing":
          return Modes.PROOFING;
        default:
          return Modes.OFF;
      }
    })();

    if (mode !== newMode) {
      // If more that 1 program is available, delay mode-change until a program has been selected in the flyout menu.
      if (storageFlyoutOpen || proofingFlyoutOpen) {
        // Queue task starting program select countdown til after stopping the current countdown (if any)
        queueMicrotask(() => {
          programSelectCountdown = AUTO_SELECT_COUNTDOWN;
          countdownTimer = window.setInterval(() => {
            programSelectCountdown--;
            if (programSelectCountdown <= 0) {
              closeFlyouts();
              mode = newMode;
            }
          }, 1000);
        });
        return;
      }

      let save = { autoFlyoutOpen, storageFlyoutOpen, proofingFlyoutOpen }; // Preserve flyout states
      mode = newMode;
      queueMicrotask(() => {
        // Restore flyout state now that mode change subscribers has been notified
        autoFlyoutOpen = save.autoFlyoutOpen;
        storageFlyoutOpen = save.storageFlyoutOpen;
        proofingFlyoutOpen = save.proofingFlyoutOpen;
      });
    }
  }

  function onSubClick(menu: string, e: MouseEvent) {
    programSelectCountdown = 0;

    let id = getClickId(e);
    if (!id) return;

    switch (menu) {
      // main menu handling
      case "main": {
        closeSubMenus();
        switch (id) {
          case "programs":
            programsOpen = true;
            return;
          case "user":
            userOpen = true;
            return;
          case "info":
            infoOpen = true;
            return;
          case "system":
            systemOpen = true;
            return;
        }
        break;
      }
      case "programs": {
        programsAutoOpen = false;
        switch (id) {
          case "auto":
            programsAutoOpen = true;
            return;
        }
      }
      case "user":
        userAlarmsOpen = false;
        switch (id) {
          case "alarm":
            userAlarmsOpen = true;
            return;
        }
        break;
      case "system":
        systemConfiguratorOpen = false;
        systemDebugOpen = false;
        systemServiceOpen = false;
        switch (id) {
          case "configurator":
            systemConfiguratorOpen = true;
            return;
          case "debug":
            systemDebugOpen = true;
            return;
          case "service":
            systemServiceOpen = true;
            return;
        }
        break;
    }

    //closeAllFlyoutsAndMenus();
    dispatch("menu-select", menu + "." + id);
  }

  function setStorageProgram(program: string) {
    mutateConfig((c) => {
      c.storage.program = program;
    });
    eventBus.emit(Events.preProgramChange, undefined, program);
    storageProgram.set(program);
    mode = Modes.STORAGE;
  }

  function setProofingProgram(program: string) {
    mutateConfig((c) => {
      c.proofing.program = program;
    });
    eventBus.emit(Events.preProgramChange, undefined, program);
    proofingProgram.set(program);
    mode = Modes.PROOFING;
  }

  function onFlyoutClick(flyout: string, e: MouseEvent) {
    let id = getClickId(e);
    if (!id) return;

    programSelectCountdown = 0;

    switch (flyout) {
      case "auto":
        autoFlyoutOpen = false;
        console.assert(e.target instanceof HTMLButtonElement);
        eventBus.emit(Events.menuAutoCommand, undefined, (e.target as HTMLButtonElement).dataset.id);
        break;
      case "storage":
        storageFlyoutOpen = false;
        console.assert(e.target instanceof HTMLButtonElement);
        setStorageProgram((e.target as HTMLButtonElement).attributes["data-id"].value);
        return;
      case "proofing":
        proofingFlyoutOpen = false;
        console.assert(e.target instanceof HTMLButtonElement);
        setProofingProgram((e.target as HTMLButtonElement).attributes["data-id"].value);
        return;
    }
    dispatch("menu-select", flyout + "." + id);
  }

  function onGlobalKeydown(e: KeyboardEvent) {
    if (e.key === "Escape" && anyMenusOpen && !document.querySelector("dialog")) {
      closeAllFlyoutsAndMenus();
      e.preventDefault();
      return;
    }
  }

  // Auto scale menu text to fit the menu width
  function scaleMenuText() {
    if (!menuRef) return;
    let spans = Array.from(menuRef.querySelectorAll<HTMLSpanElement>("button[data-id] span"));
    if (!spans.length) return;
    let widths = measureText(
      window.getComputedStyle(spans[0]).font,
      spans.map((e) => e.textContent!.toLocaleUpperCase())
    );
    let menuWidth = menuRef.clientWidth;
    if (!Array.isArray(widths)) return;
    for (let i = 0; i < spans.length; i++) {
      if (widths[i] <= menuWidth) spans[i].style.transform = "";
      else spans[i].style.transform = `scaleX(${menuWidth / widths[i]})`;
    }
  }

  $: if (menuRef && menuRef.clientWidth > 0 && $locale) {
    queueMicrotask(scaleMenuText); // Wait for the menu to be rendered
  }

  function selectLanguage(name: string) {
    locale.set(name);
  }

  function onMenuFocusIn() {
    eventBus.emit(Events.menuActivation);
  }

  function toggleDebugConsole() {
    $showDebugConsole = !$showDebugConsole;
    closeAllFlyoutsAndMenus();
  }

  function parseRat(rat?: number) {
    if (rat === undefined) return "";
    switch (rat) {
      case 0:
        return "No service";
      case 1:
        return "GSM";
      case 2:
        return "EDGE";
      case 3:
        return "UMTS";
      case 4:
        return "HSDPA";
      case 5:
        return "HSUPA";
      case 6:
        return "HSPA";
      case 7:
        return "LTE";
      case 8:
        return "LTE-A";
      default:
        return "Unknown";
    }
  }
</script>

<svelte:head>
  <link rel="prefetch" href="/img/menu-arrow.svg" as="image" type="image/svg+xml" crossorigin="anonymous" />
  <link rel="prefetch" href="/img/menu-ikon-auto.svg" as="image" type="image/svg+xml" crossorigin="anonymous" />
  <link rel="prefetch" href="/img/menu-ikon-lager.svg" as="image" type="image/svg+xml" crossorigin="anonymous" />
  <link rel="prefetch" href="/img/menu-ikon-raskning.svg" as="image" type="image/svg+xml" crossorigin="anonymous" />
  <link rel="prefetch" href="/img/menu-ikon-standby.svg" as="image" type="image/svg+xml" crossorigin="anonymous" />
  <link rel="prefetch" href="/flags/80x60/da-DK.png" as="image" />
  <link rel="prefetch" href="/flags/80x60/de-DE.png" as="image" />
  <link rel="prefetch" href="/flags/80x60/en-GB.png" as="image" />
  <link rel="prefetch" href="/flags/80x60/en-US.png" as="image" />
  <link rel="prefetch" href="/flags/80x60/fi-FI.png" as="image" />
  <link rel="prefetch" href="/flags/80x60/nb-NO.png" as="image" />
  <link rel="prefetch" href="/flags/80x60/nl.png" as="image" />
</svelte:head>

{#if anyMenusOpen}
  <div class="menu-overlay" on:click|stopPropagation={closeAllFlyoutsAndMenus} transition:fade={{ duration: 200 }} />
{/if}

<aside
  bind:this={menuRef}
  class="menu main"
  on:click|stopPropagation={onMenuClick}
  tabindex="-1"
  on:mousedown|capture={(e) => menuRef.focus()}
  on:focusin={onMenuFocusIn}
  class:hide-flyout-indicators={locked}
  style:--menu-button-height={menuButtonHeight + "px"}
>
  {#if $config}
    <button
      use:slowClick={{ clickDelay: 0 }}
      type="button"
      data-id="menu"
      class="hamburger"
      class:open={mainOpen}
      bind:clientHeight={menuButtonHeight}
    >
      <div class="line" />
      <div class="line" />
      <div class="line" />
      <span>{$_("menu.menu")}</span>
    </button>
    {#if showAuto}
      <button
        use:slowClick={{ bypassClass: "flyout-open" }}
        type="button"
        data-id="auto"
        class="group1"
        disabled={mainOpen}
        class:active={mode === Modes.AUTO}
        class:flyout-open={autoFlyoutOpen}
      >
        <img src="/img/menu-ikon-auto.svg" draggable="false" />
        <span>{$_("Auto")}</span>

        <span class="flyout-indicator" />
        {#if autoFlyoutOpen}
          <div class="flyout" on:click|stopPropagation={(e) => onFlyoutClick("auto", e)} transition:menuTransition>
            <button type="button" data-id="stop">STOP</button>
            <!-- <button type="button" data-id="next-phase">{$_("jump_to_next_phase")}</button> -->
            <button type="button" data-id="restart">{$_("restart")}</button>
          </div>
        {/if}
      </button>
    {/if}
    {#if showStorage}
      <button
        use:slowClick={{ bypassClass: "flyout-open" }}
        type="button"
        data-id="storage"
        class="group1"
        disabled={mainOpen}
        class:flyout-open={storageFlyoutOpen}
        class:active={mode === Modes.STORAGE}
      >
        <img src="/img/menu-ikon-lager.svg" draggable="false" />
        <span>{$_("Storage")}</span>

        {#if storagePrograms?.length > 1}
          <span class="flyout-indicator" />
          {#if storageFlyoutOpen}
            <div class="flyout" on:click|stopPropagation={(e) => onFlyoutClick("storage", e)} transition:menuTransition>
              {#each storagePrograms as program}
                <FlyoutProgramItem {program} selectedProgram={$storageProgram} countdown={programSelectCountdown} />
              {/each}
            </div>
          {/if}
        {/if}
      </button>
    {/if}
    {#if showProofing}
      <button
        use:slowClick={{ bypassClass: "flyout-open" }}
        type="button"
        data-id="proofing"
        class="group1"
        disabled={mainOpen}
        class:flyout-open={proofingFlyoutOpen}
        class:active={mode === Modes.PROOFING}
      >
        <img src="/img/menu-ikon-raskning.svg" draggable="false" />
        <span>{$_("Proofing")}</span>

        {#if proofingPrograms?.length > 1}
          <span class="flyout-indicator" />
          {#if proofingFlyoutOpen}
            <div class="flyout" on:click|stopPropagation={(e) => onFlyoutClick("proofing", e)} transition:menuTransition>
              {#each proofingPrograms as program}
                <FlyoutProgramItem {program} selectedProgram={$proofingProgram} countdown={programSelectCountdown} />
              {/each}
            </div>
          {/if}
        {/if}
      </button>
    {/if}

    {#if $equipment?.includes("ROOM_LIGHT")}
      <button use:slowClick={{ clickDelay: 0 }} type="button" data-id="light" disabled={mainOpen} class:active={$s_light}>
        <svg
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style:color={$s_light ? "var(--clr-company)" : "white"}
          style="transition: color 0.2s ease, opacity 0.2s ease;"
        >
          <circle cx="24.6667" cy="16.6667" r="14.6667" stroke="currentColor" stroke-width="4" />
          <circle cx="24.6667" cy="16.6667" r="14.6667" stroke="currentColor" fill="none" stroke-width="4" />
          <line x1="15" y1="38" x2="34.3333" y2="38" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
          <line x1="15" y1="43" x2="34.3333" y2="43" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
          <line x1="19" y1="48" x2="30.8333" y2="48" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
        </svg>
        <span>{$_("Light")}</span>
      </button>
    {/if}

    <button use:slowClick type="button" data-id="standby" class="group1" disabled={mainOpen} class:active={mode === Modes.OFF}>
      <img src="/img/menu-ikon-standby.svg" draggable="false" />
      <span>{$_("Standby")}</span>
    </button>

    {#if mainOpen}
      <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("main", e)} transition:menuTransition>
        <MenuItem id="programs" active={programsOpen}>
          {$_("menu.programs")}<span class="flyout-indicator" />
        </MenuItem>
        <MenuItem id="user" active={userOpen}>
          {$_("menu.user")}<span class="flyout-indicator" />
        </MenuItem>
        <MenuItem id="info" active={infoOpen}>
          {$_("menu.info")}<span class="flyout-indicator" />
        </MenuItem>
        <hr />
        <MenuItem id="system" active={systemOpen}>
          {$_("menu.system")}<span class="flyout-indicator" />
        </MenuItem>
        {#if $config && $config.system.quickLanguages.length}
          <div class="quick-language">
            {#each [...$config.system.quickLanguages].sort() as loc}
              {#if $locales.includes(loc)}
                <button on:click={() => selectLanguage(loc)} class:selected={$locale === loc}>
                  <img src="/flags/80x60/{loc}.png" alt={loc} />
                </button>
              {/if}
            {/each}
          </div>
        {/if}
        <!-- FIXME: Remove this when the debug is no longer needed :DebugConsole -->
        {#if enableDebugConsole}
          <div class="debug-console-icon">
            <Button naked size="xsmall" on:click={toggleDebugConsole}>
              <DebugConsoleIcon height="2rem" color={$showDebugConsole ? "green" : "gray"} />
            </Button>
          </div>
        {/if}
        {#if $modemStatus}
          <div class="modem-status" class:online={$modemStatus?.rssi > 0}>
            <div class="signal">
              <CellSignalIcon height="2.4rem" />
              <div class="signal-overlay" style:--width={100 - Math.round($modemStatus.rssi * (100 / 31)) + "%"}></div>
            </div>
            {#if !$modemStatus.sim_ready}
              <span class="blink" style:color="red">SIM?</span>
            {:else}
              <span>{parseRat($modemStatus?.rat)}</span>
            {/if}
          </div>
        {/if}
        <div class="version">
          <div>{$_("order_no")}: <span class="nowrap">{$config.general.orderNumber || "?"}</span></div>
          <div>{$_("version")}: {$state.version || "?"}</div>
        </div>

        {#if programsOpen}
          <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("programs", e)} transition:menuTransition>
            {#if showAuto}
              <MenuItem id="auto" active={programsAutoOpen}>
                <span>{$_("Auto")}</span>
                <span class="flyout-indicator" />
              </MenuItem>
            {/if}
            {#if hasAutoStart}
              <MenuItem id="auto-start" locked={$userLocked}>
                <span>{$_("Auto") + " " + $_("start")}</span>
              </MenuItem>
            {/if}
            {#if showStorage || hasAuto}
              <MenuItem id="storage" locked={$userLocked}>
                <span>{$_("Storage")}</span>
              </MenuItem>
            {/if}
            {#if showProofing || hasAuto}
              <MenuItem id="proofing" locked={$userLocked}>
                <span>{$_("Proofing")}</span>
              </MenuItem>
            {/if}

            {#if defrostAvailable}
              <MenuItem id="defrost" locked={$userLocked}>
                <span>{$_("Defrost")}</span>
              </MenuItem>
            {/if}

            {#if programsAutoOpen}
              <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("programs.auto", e)} transition:menuTransition>
                <MenuItem id="schedule" locked={$userLocked}>
                  <span>{$_("menu.schedule")}</span>
                </MenuItem>
                <MenuItem id="program" locked={$userLocked}>
                  <span>{$_("menu.program")}</span>
                </MenuItem>
                <MenuItem id="curve" disabled locked={$userLocked}>
                  <span>{$_("menu.curve")}</span>
                </MenuItem>
              </div>
            {/if}
          </div>
        {/if}

        {#if userOpen}
          <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("user", e)} transition:menuTransition>
            <MenuItem id="language">
              <svg viewBox="0 0 24 24" fill="none" stroke="#ffffff">
                <g stroke-width="0" /><g stroke-linecap="round" stroke-linejoin="round" /><g>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11 3.05505V8.94321C9.46342 8.76785 8.04833 8.19313 6.97374 7.32002C6.37674 6.83496 5.91152 6.281 5.58308 5.68956C6.99611 4.25287 8.88792 3.28853 11 3.05505ZM13 3.05505V8.94321C14.5366 8.76785 15.9517 8.19313 17.0263 7.32002C17.6233 6.83496 18.0885 6.281 18.4169 5.68957C17.0039 4.25288 15.1121 3.28853 13 3.05505ZM19.7168 7.36596C19.3207 7.91612 18.8406 8.42284 18.2874 8.87225C16.8355 10.052 14.9677 10.7711 13 10.9537L13 13.0462C13.8146 13.1218 14.6157 13.2896 15.3804 13.547C16.4564 13.9091 17.4455 14.4435 18.2874 15.1276C18.837 15.5741 19.3182 16.0799 19.717 16.6339C20.5315 15.2803 21 13.6949 21 12.0001C21 10.3051 20.5314 8.7196 19.7168 7.36596ZM18.417 18.3106C18.0861 17.7153 17.6183 17.1609 17.0263 16.6799C16.3825 16.1568 15.6078 15.7337 14.7425 15.4425C14.1847 15.2548 13.599 15.1252 13 15.0568V20.9452C15.1121 20.7117 17.004 19.7473 18.417 18.3106ZM11 20.9452L11 15.0568C10.401 15.1252 9.81528 15.2548 9.2575 15.4425C8.39222 15.7337 7.61751 16.1568 6.97374 16.6799C6.38168 17.1609 5.91388 17.7153 5.58301 18.3106C6.99604 19.7473 8.88788 20.7117 11 20.9452ZM4.28297 16.6339C4.68176 16.0799 5.16302 15.5741 5.71255 15.1276C6.55451 14.4435 7.54363 13.9091 8.61956 13.547C9.38428 13.2896 10.1854 13.1218 11 13.0462V10.9537C9.03233 10.7711 7.16455 10.052 5.71255 8.87225C5.15944 8.42284 4.67933 7.91612 4.28321 7.36596C3.46856 8.7196 3 10.3051 3 12.0001C3 13.6949 3.46846 15.2803 4.28297 16.6339Z"
                    fill="#33363F"
                  />
                </g>
              </svg>
              <span>{$_("menu.language")}</span>
            </MenuItem>
            <MenuItem id="datetime" locked={$userLocked}>{$_("menu.datetime")}</MenuItem>
            <MenuItem id="screen" locked={$userLocked}>{$_("menu.screen")}</MenuItem>
            <MenuItem id="alarm" active={userAlarmsOpen}>
              {$_("menu.alarms")}<span class="flyout-indicator" />
            </MenuItem>
            <MenuItem id="timers" locked={$userLocked}>{$_("menu.timers")}</MenuItem>
            <MenuItem id="remote" disabled locked={$userLocked}>{$_("menu.network")}</MenuItem>
            <MenuItem id="energy" disabled locked={$userLocked}>{$_("menu.energy")}</MenuItem>
            <MenuItem id="backup" disabled locked={$userLocked}>{$_("menu.backup_restore")}</MenuItem>

            {#if userAlarmsOpen}
              <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("alarms", e)} transition:menuTransition>
                <MenuItem id="filter" locked={$userLocked}>{$_("menu.filter")}</MenuItem>
                <MenuItem id="settings" locked={$userLocked}>{$_("menu.settings")}</MenuItem>
              </div>
            {/if}
          </div>
        {/if}

        {#if infoOpen}
          <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("info", e)} transition:menuTransition>
            <MenuItem id="alarmlist">{$_("menu.alarmlist")}</MenuItem>
            <MenuItem id="energy" disabled>{$_("menu.energy")}</MenuItem>
            <MenuItem id="logs">{$_("menu.logs")}</MenuItem>
            <MenuItem id="graphs">{$_("menu.graphs")}</MenuItem>
            <MenuItem id="io">{$_("menu.io_state")}</MenuItem>
            <MenuItem id="info" disabled>{$_("menu.facility_info")}</MenuItem>
            <MenuItem id="docs" disabled>{$_("menu.documentation")}</MenuItem>
          </div>
        {/if}

        {#if systemOpen}
          <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("system", e)} transition:menuTransition>
            <MenuItem id="configurator" active={systemConfiguratorOpen}>
              Konfigurator<span class="flyout-indicator" />
            </MenuItem>
            <MenuItem id="debug" active={systemDebugOpen}>
              Debug<span class="flyout-indicator" />
            </MenuItem>
            <MenuItem id="service" active={systemServiceOpen}>
              Service<span class="flyout-indicator" />
            </MenuItem>

            {#if systemConfiguratorOpen}
              <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("system.configurator", e)} transition:menuTransition>
                <MenuItem id="programs">Accessible programs</MenuItem>
                <MenuItem id="brand">Brand skin</MenuItem>
                <MenuItem id="io-bus" disabled>IO bus settings</MenuItem>
                <MenuItem id="equipment">Equipment config</MenuItem>
                <MenuItem id="modbus-slave">{$_("menu.modbus_slave")}</MenuItem>
                <MenuItem id="profiles" disabled>Load/save profiles</MenuItem>
                <MenuItem id="screen" disabled>Touch screen type</MenuItem>
                <MenuItem id="first-time-setup" disabled={!isDevMode}>First-time Setup</MenuItem>
              </div>
            {/if}

            {#if systemDebugOpen}
              <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("system.debug", e)} transition:menuTransition>
                <MenuItem id="manual_operation">{$_("menu.manual_operation")}</MenuItem>
                <MenuItem id="comm" disabled>Communication</MenuItem>
                <MenuItem id="flexio" disabled>FlexIO info</MenuItem>
                <MenuItem id="log">log</MenuItem>
              </div>
            {/if}

            {#if systemServiceOpen}
              <div class="menu sub" on:click|stopPropagation={(e) => onSubClick("system.service", e)} transition:menuTransition>
                <MenuItem id="upgrade">Software Upgrade</MenuItem>
                <MenuItem id="backup">Backup</MenuItem>
                <MenuItem id="restore">Restore</MenuItem>
                <MenuItem id="factory_init" disabled>Factory Setup</MenuItem>
                <MenuItem id="reboot">Reboot</MenuItem>
              </div>
            {/if}
          </div>
        {/if}
      </div>
    {/if}
    {#if anyMenusOpen && !anyFlyoutsOpen}
      <div class="menu-overlay toolbar" on:click|stopPropagation={closeAllFlyoutsAndMenus} />
    {/if}
  {/if}
</aside>

<svelte:window on:keydown={onGlobalKeydown} />
