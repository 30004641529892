<script lang="ts">
  import NumberInput from "@/lib/NumberInput.svelte";
  import Switch from "@/lib/Switch.svelte";
  import DurationIcon from "@/svg/DurationIcon.svelte";
  import { durationString } from "@/utils";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import StorageSettings from "./_StorageSettings.svelte";
  import { calcLinearDuration, ensureCelcius, hasUV, type AutoProgram } from "./auto";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: AutoProgram;
  export let storageProgramItems: ListItem[];

  const dispatch = createEventDispatcher();

  $: cooling = data.cooling;
  $: calculatedTimeString = (() => {
    let s = calcLinearDuration(ensureCelcius(data.proofing.t), ensureCelcius(cooling.t), cooling.factor);
    if (s === +Infinity) return $_(">24h");
    return durationString(s);
  })();

  function triggerReactivity() {
    cooling = cooling;
    dispatch("change");
  }
</script>

<div class="edit-container">
  <StorageSettings data={cooling} {storageProgramItems} on:change={triggerReactivity} />
  <label>{$_("factor")}:</label>
  <div class="flex-line">
    <NumberInput
      label={$_("Factor")}
      width="18rem"
      incdec
      step={1}
      min={0}
      max={100}
      bind:value={cooling.factor}
      digit
      hidelabel
      on:change
    />
    <span>°/h</span>
  </div>

  <label />
  <div class="flex-line">
    <div class="dim flex-line">
      {calculatedTimeString}
      <DurationIcon height="3rem" color="var(--clr-field-icon)" />
    </div>
  </div>

  <label>{$_("Light")}:</label>
  <Switch bind:checked={cooling.light} on:change />

  {#if $hasUV}
    <label>{$_("UV")}:</label>
    <Switch bind:checked={cooling.uv} on:change />
  {/if}
</div>

<style lang="scss">
  .edit-container {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-content: start;
    align-items: center;
  }
</style>
