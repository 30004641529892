<script lang="ts" context="module">
  let selectedProp: string | undefined = undefined;
</script>

<script lang="ts">
  import { api } from "@/api";
  import Editor from "@/config/property-editor/Editor.svelte";
  import dialog from "@/dialog";
  import { config, DEFAULT_DEBOUNCED_SAVE_MS, equipment, programs } from "@/hmi";
  import ListInput from "@/lib/ListInput.svelte";
  import { usesFarenheight } from "@/stores";
  import { CtoF, forFields, FtoC } from "@/utils";
  import { debounce, isEqual } from "lodash-es";
  import { onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { lookupHelp } from "./helpers";
  import _props from "./proofing-props.json";

  const props = _props as PropConfig;
  let proofing: DTO.ProofingProgram;
  let lastSavedProofing: DTO.ProofingProgram;
  let changeCounter = 0;
  let debounceSave = debounce(save, DEFAULT_DEBOUNCED_SAVE_MS);

  let currentProgram: string | undefined = undefined;
  let selectedProgram = $config.proofing.program ?? "";
  $: programsSelection = $programs?.proofing?.programs?.map((x) => {
    return {
      value: x.slot,
      text: x.slot + ": " + (x.name ?? ""),
    };
  });

  onMount(() => {
    if (!selectedProgram && programsSelection?.length) selectedProgram = programsSelection[0].value;
  });

  const help = (key: string, loc: string) => lookupHelp("proofing", key, loc);

  let backups = new Map<string, DTO.ProofingProgram>();
  let canRevert = false;
  const debounceUpdateRevert = debounce(() => {
    canRevert = proofing && backups.has(currentProgram) && !isEqual(backups.get(currentProgram), proofing);
  }, 100);

  $: if (changeCounter > 0) {
    debounceUpdateRevert();
    debounceSave();
  }

  // Ensure the selected program is valid
  $: if (programsSelection.findIndex((v) => v.value === selectedProgram) === -1 && programsSelection?.length)
    selectedProgram = programsSelection[0].value;

  $: if (selectedProgram && currentProgram !== selectedProgram) {
    currentProgram = selectedProgram;
    api.loadProgram<DTO.ProofingProgram>("proofing", selectedProgram).then((p) => {
      proofing = p;
      if (!proofing) return;
      toUserTempUnit(proofing);
      lastSavedProofing = structuredClone(proofing);
      if (!backups.has(currentProgram)) backups.set(currentProgram, structuredClone(proofing));
      validateProgram(proofing);
      changeCounter++;
    });
  }

  onDestroy(() => {
    debounceSave.flush();
  });

  async function save() {
    if (!proofing) return;
    if (isEqual(proofing, lastSavedProofing)) return;
    let saveData = structuredClone(proofing);
    toServerTempUnit(proofing);
    api.saveProgram("proofing", selectedProgram, saveData);
    lastSavedProofing = structuredClone(proofing);
  }

  async function revert() {
    console.assert(backups.has(currentProgram));
    if (!(await dialog.confirm("Are you sure you want to revert all changes?"))) return;
    proofing = structuredClone(backups.get(currentProgram));
    changeCounter++;
  }

  $: validTypes = $config?.system?.accessiblePrograms.filter((x) => x.startsWith("PROOFING")).map((x) => x.slice(9));

  function validateProgram(ap: DTO.ProofingProgram) {
    if (!validTypes?.length) return; // FIXME: Show error message?
    if (!validTypes.find((x) => x === ap.type)) {
      console.warn("Invalid type:", ap.type);
      ap.type = validTypes[0];
      debounceSave();
    }
    if (!$equipment?.includes("STEAM_EXTERNAL_SIGNAL")) {
      if (!isEqual(ap.steam_systems, { internal: true, external: false })) {
        console.warn("Resetting steam systems to internal only");
        ap.steam_systems = { internal: true, external: false };
        debounceSave();
      }
    }
  }

  // Fields that maybe needs to be converted to/from °F.
  const tempFields = ["setpoints.t"];

  // Convert (if needed) from server temperture unit (°C) to user selected unit (°F or °C)
  // The result is rounded to the nearest integer.
  export function toUserTempUnit(ap: DTO.ProofingProgram): void {
    if (!$usesFarenheight) return;
    forFields(ap, tempFields, (v: number) => Math.round(CtoF(v) * 10) / 10.0);
  }

  // Convert (if needed) from user temperture unit (°F or °C) to server unit (°C)
  // The result is rounded to the nearest integer.
  export function toServerTempUnit(ap: DTO.ProofingProgram): void {
    if (!$usesFarenheight) return;
    forFields(ap, tempFields, (v: number) => Math.round(FtoC(v) * 10) / 10.0);
  }
</script>

{#if proofing}
  <Editor
    title={$_("programs.proofing.@title")}
    localePrefix="programs.proofing"
    {props}
    data={proofing}
    bind:selected={selectedProp}
    bind:changeCounter
    bind:programSlot={selectedProgram}
    {help}
    helpId="PROOFING_PROGRAM"
    {revert}
    {canRevert}
    type="proofing"
  >
    <div class="flex-line" slot="before">
      {#if programsSelection?.length}
        <ListInput label="Program" width="28rem" items={programsSelection} bind:selectedValue={selectedProgram} />
      {/if}
    </div>
  </Editor>
{/if}
