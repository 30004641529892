<script lang="ts">
  import { showHelp } from "@/help";
  import CloseIcon from "@/svg/CloseIcon.svelte";
  import HelpIcon from "@/svg/HelpIcon.svelte";
  import RevertIcon from "@/svg/RevertIcon.svelte";
  import { getContext, onMount } from "svelte";

  export let title: string;
  export let helpId: string = undefined;
  export let revert: () => void = undefined;
  export let canRevert: boolean = false;
  export let titlebarColor: string = undefined;

  let dialogRef: HTMLDialogElement;
  let { done } = getContext<DialogContext>("dialog");

  onMount(() => {
    dialogRef.showModal();
  });

  export function close(result: any = null) {
    done(dialogRef, result);
  }
</script>

<dialog bind:this={dialogRef} on:cancel|preventDefault={() => close(null)} on:close={() => close(null)} tabindex="-1">
  <div class="titlebar" style:--bg-color={titlebarColor}>
    <div class="title-text"><div>{title}</div></div>
    <slot name="title_extra" />
    <div>
      {#if revert}
        <button class="tool-button" on:click={revert} disabled={!canRevert}>
          <RevertIcon style="color:white" />
        </button>
      {/if}
      {#if helpId}
        <button class="tool-button" on:click={() => showHelp(helpId)}><HelpIcon /></button>
      {/if}
      <button class="tool-button close" on:click={() => close(null)}><CloseIcon /></button>
    </div>
  </div>
  <div class="body">
    <slot />
  </div>
</dialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  dialog {
    --transform-scale-min: 0;

    max-width: unset;
    max-height: unset;
    width: var(--screen-width);
    height: var(--screen-height);

    top: 0;
    bottom: auto;

    display: grid;
    grid-template-rows: auto 1fr;

    &::backdrop {
      background-color: transparent;
      backdrop-filter: unset;
    }

    transform-origin: var(--click-point-x) var(--click-point-y);
    &[open] {
      animation: var(--animation-duration) ease-out forwards dialog-open;
    }
    &:global(.closing) {
      animation: var(--animation-duration) ease-out forwards dialog-close;
    }

    .titlebar {
      font-size: 2.8rem;
      display: flex;
      justify-content: space-between;
      text-transform: capitalize;
      background-color: var(--bg-color, $background);
      z-index: 1;
      box-shadow: 0 0 1rem 0.25rem $background;
      border-bottom: 0.2rem solid rgba(0, 0, 0, 0.15);
      white-space: nowrap;

      .title-text {
        display: grid;
        align-items: center;
        padding-inline: 1rem;
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      :global(button) {
        background-color: transparent;
        color: $primary;
        padding: 0.5rem;
        transition: background-color 0s;
        height: 100%;
        aspect-ratio: 1;
        width: 4.8rem; // Needed for stupid Safari1

        &:active {
          background-color: lighten($background, 10%);
        }

        &:disabled {
          opacity: 0.3;
          pointer-events: none !important;
        }
      }
    }
    .body {
      flex-grow: 1;

      // Use flex mode for supporting scrolling in child
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .close {
      margin-left: 2rem;
    }
  }
  // FIXME: Hack to show config dialog content on iPad as using flexbox above does not appear to work!
  body.safari {
    dialog .body {
      height: calc(var(--screen-height) - 69px); // 69px is the height of the titlebar
    }
  }
</style>
