<script lang="ts">
  import { api } from "@/api";
  import hmi, { config, programs } from "@/hmi";
  import SteamPotStatusPage from "@/lib/SteamPotStatusPage.svelte";
  import TrendGraph from "@/modes/TrendGraph.svelte";
  import { proofingProgram, usesFarenheight } from "@/stores";
  import AirTreatmentPage from "./AirTreatmentPage.svelte";
  import Frame from "./Frame.svelte";
  import Swiper from "./Swiper.svelte";
  import TempAndHumidityPage from "./TempAndHumidityPage.svelte";

  const s_setpointT = hmi.getValueStore("set.proofing.T");
  const s_setpointH = hmi.getValueStore("set.proofing.H");

  let tempRange: [number, number] = [-20, 50];

  function onUpdateSetpoint(e: CustomEvent<{ setpointT: number; setpointH: number }>) {
    if ($s_setpointT === e.detail.setpointT && $s_setpointH === e.detail.setpointH) return;
    s_setpointT.set(e.detail.setpointT);
    s_setpointH.set(e.detail.setpointH);
    api.storeSetpointsInProgram($proofingProgram, e.detail.setpointT, e.detail.setpointH);
  }

  function alwaysCelcius(v: number): number {
    if (!$usesFarenheight) return v;
    return (v - 32) / 1.8; // F -> C
  }

  // Get the temperature range for the current proofing program
  $: if ($proofingProgram && $programs) {
    const all = ($programs.auto?.programs ?? []).concat($programs.proofing?.programs ?? []).concat($programs.storage?.programs ?? []);
    const p = all.find((p) => p.slot === $proofingProgram);
    if (p) tempRange = hmi.getTempRangeForType(p.type);
  }
</script>

<div class="swiper-pagination pagination-proofing" />

<Frame icon="/img/menu-ikon-raskning.svg" program={$proofingProgram}>
  <section>
    <Swiper paginationClass="pagination-proofing" virtual let:curSlide>
      <swiper-slide>
        <TempAndHumidityPage
          mode="proofing"
          setpointT={$s_setpointT || 0}
          setpointH={$s_setpointH || 0}
          TRange={tempRange}
          HRange={$config?.proofing.Hrange}
          on:update={onUpdateSetpoint}
          slideShown={curSlide === 0}
        />
      </swiper-slide>
      <swiper-slide>
        <AirTreatmentPage slideShown={curSlide === 1} />
      </swiper-slide>
      <swiper-slide>
        <TrendGraph mode="proofing" {curSlide} />
      </swiper-slide>
      <swiper-slide>
        <SteamPotStatusPage slideShown={curSlide === 3} />
      </swiper-slide>
    </Swiper>
  </section>
</Frame>

<style lang="scss">
  section {
    height: 100%;
  }
</style>
