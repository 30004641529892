<script lang="ts">
  import hmi from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { _ } from "svelte-i18n";

  export let value: DTO.Setpoints;
  export let prop: PropItem;
  export let programType: string;

  let tempRange = hmi.getTempRangeForType(programType);

  if (value === undefined && prop.default !== undefined) {
    value = structuredClone(prop.default);
  }
</script>

<section>
  <label>{$_("fixed")}:</label>
  <CheckBox bind:checked={value.fixed} on:change />

  <label class:dim={!value.fixed}>{$_("Temperature")}:</label>
  <NumberInput
    width="20rem"
    incdec
    bind:value={value.t}
    on:change
    min={tempRange[0]}
    max={tempRange[1]}
    negative
    digit
    disabled={!value.fixed}
  />

  <label class:dim={!value.fixed}>{$_("Humidity")}:</label>
  <NumberInput width="20rem" incdec bind:value={value.h} on:change min={50} max={100} digit disabled={!value.fixed} />
</section>

<style lang="scss">
  section {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 2rem 1rem;
    align-items: center;
  }
</style>
