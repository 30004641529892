<script lang="ts">
  import { _ } from "svelte-i18n";

  import { config, DEFAULT_DEBOUNCED_SAVE_MS, mutateConfig } from "@/hmi";

  import dialog from "@/dialog";
  import Button from "@/lib/Button.svelte";
  import CheckBox from "@/lib/CheckBox.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import Swiper from "@/modes/Swiper.svelte";
  import { debounce, isEqual } from "lodash-es";
  import { onDestroy, onMount } from "svelte";
  import { Pagination } from "swiper/modules";
  import ConfigDialog from "./ConfigDialog.svelte";

  const defaultRanges: typeof $config.system.ranges = {
    k: [3, 10],
    ck: [3, 20],
    fbuv: [3, 10],
    tk: [3, 10], // tørkøler
    dc: [-20, 0],
    kf: [-20, 0],
    sf: [-33, -10],
    hdc: [-22, -10],
    r: [20, 45],
    rc: [-20, 45],
    rno: [-20, 45], // Slettes ??
  };

  const numProps = {
    min: -35,
    max: 60,
    step: 1,
    incdec: true,
  };

  const delaySave = debounce(save, DEFAULT_DEBOUNCED_SAVE_MS);

  let swiperEl: HTMLElement | Swiper;
  let auto = false;
  let auto_start = false;
  let storage_k = false;
  let storage_ck = false;
  let storage_fbuv = false;
  let storage_tk = false;
  let storage_dc = false;
  let storage_kf = false;
  let storage_sf = false;
  let storage_hdc = false;
  let proofing_r = false;
  let proofing_rc = false;
  let proofing_rno = false;
  let ranges: typeof $config.system.ranges = {};

  $: notDefaultRanges = !isEqual(ranges, defaultRanges);

  if ($config?.system?.accessiblePrograms) {
    let ap = $config.system.accessiblePrograms;
    auto = ap.includes("AUTO");
    auto_start = ap.includes("AUTO_START");

    proofing_r = ap.includes("PROOFING_R");
    proofing_rc = ap.includes("PROOFING_RC");
    proofing_rno = ap.includes("PROOFING_RNO");

    storage_k = ap.includes("STORAGE_K");
    storage_ck = ap.includes("STORAGE_CK");
    storage_fbuv = ap.includes("STORAGE_FBUV");
    storage_tk = ap.includes("STORAGE_TK");
    storage_dc = ap.includes("STORAGE_DC");
    storage_kf = ap.includes("STORAGE_KF");
    storage_sf = ap.includes("STORAGE_SF");
    storage_hdc = ap.includes("STORAGE_HDC");

    ranges = $config.system.ranges || {};
  }

  onMount(() => {
    swiperEl.style.maxHeight = "730px"; // HACK: Fix overflow issue (100% doesn't work)
  });

  onDestroy(delaySave.flush);

  function save() {
    mutateConfig((c) => {
      let a: string[] = [];
      if (auto) a.push("AUTO");
      if (auto_start) a.push("AUTO_START");

      if (proofing_r) a.push("PROOFING_R");
      if (proofing_rc) a.push("PROOFING_RC");
      if (proofing_rno) a.push("PROOFING_RNO");

      if (storage_k) a.push("STORAGE_K");
      if (storage_ck) a.push("STORAGE_CK");
      if (storage_fbuv) a.push("STORAGE_FBUV");
      if (storage_tk) a.push("STORAGE_TK");
      if (storage_dc) a.push("STORAGE_DC");
      if (storage_kf) a.push("STORAGE_KF");
      if (storage_sf) a.push("STORAGE_SF");
      if (storage_hdc) a.push("STORAGE_HDC");

      c.system.accessiblePrograms = a;
      c.system.ranges = ranges;
    });
  }

  async function onResetToDefaults() {
    if (!(await dialog.confirm("Are you sure you want to reset to defaults?"))) return;
    ranges = structuredClone(defaultRanges);
    delaySave();
  }
</script>

<ConfigDialog title="Accessible Programs">
  <div slot="title_extra" class="flex-line acc-pagination"></div>
  <swiper-container bind:this={swiperEl} modules={[Pagination]} pagination={{ el: ".acc-pagination" }}>
    <swiper-slide class="page first" on:change={delaySave}>
      <div class="group">
        <h1>{$_("Auto")}</h1>
        <CheckBox bind:checked={auto} label={$_("Auto")} />
        <CheckBox bind:checked={auto_start} label={$_("Auto") + " " + $_("start")} />
      </div>
      <div class="group">
        <h1>{$_("Storage")}</h1>
        <CheckBox bind:checked={storage_k} label="K" />
        <CheckBox bind:checked={storage_ck} label="CK" />
        <CheckBox bind:checked={storage_fbuv} label="FBUV" />
        <CheckBox bind:checked={storage_tk} label="TK" />
        <CheckBox bind:checked={storage_dc} label="DC" />
        <CheckBox bind:checked={storage_kf} label="KF" />
        <CheckBox bind:checked={storage_sf} label="SF" />
        <CheckBox bind:checked={storage_hdc} label="HDC" />
      </div>
      <div class="group">
        <h1>{$_("Proofing")}</h1>
        <CheckBox bind:checked={proofing_r} label="R" />
        <CheckBox bind:checked={proofing_rc} label="RC" />
        <CheckBox bind:checked={proofing_rno} label="RNO" />
      </div>
    </swiper-slide>
    <swiper-slide class="page second" on:change={delaySave}>
      <h1 class="whole-row">Temperature ranges in °C</h1>

      <div class="ranges">
        <span class:dim={!storage_k}>K:</span>
        <NumberInput bind:value={ranges.k[0]} {...numProps} disabled={!storage_k} />
        <NumberInput bind:value={ranges.k[1]} {...numProps} disabled={!storage_k} />
        <span class:dim={!storage_ck}>CK:</span>
        <NumberInput bind:value={ranges.ck[0]} {...numProps} disabled={!storage_ck} />
        <NumberInput bind:value={ranges.ck[1]} {...numProps} disabled={!storage_ck} />
        <span class:dim={!storage_fbuv}>FBUV:</span>
        <NumberInput bind:value={ranges.fbuv[0]} {...numProps} disabled={!storage_fbuv} />
        <NumberInput bind:value={ranges.fbuv[1]} {...numProps} disabled={!storage_fbuv} />
        <span class:dim={!storage_tk}>TK:</span>
        <NumberInput bind:value={ranges.tk[0]} {...numProps} disabled={!storage_tk} />
        <NumberInput bind:value={ranges.tk[1]} {...numProps} disabled={!storage_tk} />
        <span class:dim={!storage_dc}>DC:</span>
        <NumberInput bind:value={ranges.dc[0]} {...numProps} disabled={!storage_dc} />
        <NumberInput bind:value={ranges.dc[1]} {...numProps} disabled={!storage_dc} />
        <span class:dim={!storage_kf}>KF:</span>
        <NumberInput bind:value={ranges.kf[0]} {...numProps} disabled={!storage_kf} />
        <NumberInput bind:value={ranges.kf[1]} {...numProps} disabled={!storage_kf} />
      </div>

      <div class="ranges">
        <span class:dim={!storage_sf}>SF:</span>
        <NumberInput bind:value={ranges.sf[0]} {...numProps} disabled={!storage_sf} />
        <NumberInput bind:value={ranges.sf[1]} {...numProps} disabled={!storage_sf} />
        <span class:dim={!storage_hdc}>HDC:</span>
        <NumberInput bind:value={ranges.hdc[0]} {...numProps} disabled={!storage_hdc} />
        <NumberInput bind:value={ranges.hdc[1]} {...numProps} disabled={!storage_hdc} />

        <hr class="whole-row" />

        <span class:dim={!proofing_r}>R:</span>
        <NumberInput bind:value={ranges.r[0]} {...numProps} disabled={!proofing_r} />
        <NumberInput bind:value={ranges.r[1]} {...numProps} disabled={!proofing_r} />
        <span class:dim={!proofing_rc}>RC:</span>
        <NumberInput bind:value={ranges.rc[0]} {...numProps} disabled={!proofing_rc} />
        <NumberInput bind:value={ranges.rc[1]} {...numProps} disabled={!proofing_rc} />
        <span class:dim={!proofing_rno}>RNO:</span>
        <NumberInput bind:value={ranges.rno[0]} {...numProps} disabled={!proofing_rno} />
        <NumberInput bind:value={ranges.rno[1]} {...numProps} disabled={!proofing_rno} />
      </div>

      <div class="whole-row defaults">
        <Button outline size="small" on:click={onResetToDefaults} disabled={!notDefaultRanges}>Reset to defaults...</Button>
      </div>
    </swiper-slide>
  </swiper-container>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .page {
    background-color: transparent;
    padding: $dialog-padding;
    font-family: hmiFont;
    font-size: 2.6rem;
    align-content: start;

    &.first {
      display: flex;
      margin-block: auto;
      justify-content: space-evenly;

      h1 {
        font-size: 3rem;
        font-weight: bold;
        color: var(--clr-company);
      }
    }

    &.second {
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: space-around;
      align-items: start;

      h1 {
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        color: var(--clr-company);
        margin-bottom: 2rem;
      }

      hr {
        margin-block: 2.4rem;
      }
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .ranges {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: start;
    align-items: center;
    gap: 1rem;
  }

  .acc-pagination {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .defaults {
    justify-self: right;
    margin-top: 4rem;
  }
</style>
