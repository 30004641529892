<script lang="ts">
  import { api } from "@/api";
  import hmi, { config, equipment, programs } from "@/hmi";
  import { storageProgram } from "@/stores";
  import ActiveDefrostStatus from "./ActiveDefrostStatus.svelte";
  import AirTreatmentPage from "./AirTreatmentPage.svelte";
  import Frame from "./Frame.svelte";
  import Swiper from "./Swiper.svelte";
  import TempAndHumidityPage from "./TempAndHumidityPage.svelte";
  import TrendGraph from "./TrendGraph.svelte";

  const s_setpointT = hmi.getValueStore("set.storage.T");
  const s_setpointH = hmi.getValueStore("set.storage.H");
  const defrostStatus = hmi.getObjectStore<DTO.DefrostStatus>("DefrostStatus");
  const hasActiveDefrost = hmi.getValueStore("sys.has_active_defrost");

  let showHumidity = false;
  let tempRange: [number, number] = [-20, 50];

  $: {
    const prog = $programs?.storage?.programs?.find((p) => p.slot === $storageProgram);
    showHumidity = prog?.use_humidity && prog?.type === "FBUV" && $equipment?.includes("HYGROMETER");
  }

  function onUpdateSetpoint(e: CustomEvent<{ setpointT: number; setpointH: number }>) {
    if ($s_setpointT === e.detail.setpointT && $s_setpointH === e.detail.setpointH) return;
    s_setpointT.set(e.detail.setpointT);
    s_setpointH.set(e.detail.setpointH);
    api.storeSetpointsInProgram($storageProgram, e.detail.setpointT, e.detail.setpointH);
  }

  // Get the temperature range for the current proofing program
  $: if ($storageProgram && $programs) {
    const all = ($programs.auto?.programs ?? []).concat($programs.proofing?.programs ?? []).concat($programs.storage?.programs ?? []);
    const p = all.find((p) => p.slot === $storageProgram);
    if (p) tempRange = hmi.getTempRangeForType(p.type);
  }
</script>

<div class="swiper-pagination pagination-storage" />

<Frame icon="/img/menu-ikon-lager.svg" program={$storageProgram}>
  <section>
    {#key $hasActiveDefrost}
      <Swiper paginationClass="pagination-storage" virtual let:curSlide>
        <swiper-slide>
          <TempAndHumidityPage
            mode="storage"
            setpointT={$s_setpointT || 0}
            setpointH={$s_setpointH || 0}
            showHumidity={!!showHumidity}
            TRange={tempRange}
            HRange={$config?.storage.Hrange}
            on:update={onUpdateSetpoint}
            slideShown={curSlide === 0}
          />
        </swiper-slide>
        <swiper-slide>
          <AirTreatmentPage showHumiditySetpoint={!!showHumidity} slideShown={curSlide === 1} />
        </swiper-slide>
        <swiper-slide>
          <TrendGraph mode="storage" {curSlide} />
        </swiper-slide>
        {#if $hasActiveDefrost}
          <swiper-slide>
            <ActiveDefrostStatus status={$defrostStatus} />
          </swiper-slide>
        {/if}

        <!--
         <swiper-slide>
        <div class="flex-center column" style:margin-top="-5rem">
          <Cooling />
        </div>
      </swiper-slide>
      <swiper-slide>
        <Defrost />
      </swiper-slide>
      -->
      </Swiper>
    {/key}
  </section>
</Frame>
