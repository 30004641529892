<script lang="ts">
  import { api } from "@/api";
  import dialog from "@/dialog";
  import PlayIcon from "@/svg/PlayIcon.svelte";
  import CheckIcon from "@/svg/phCheckBold.svelte";
  import { Events, clamp, convertRemToPixels, eventBus, pad2 } from "@/utils";
  import { debounce } from "lodash-es";
  import { onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import CountdownEdit from "./CountdownEdit.svelte";

  export let timer: CountdownTimer;
  export let duration: number; // Minutes
  export let state: string;

  let prevDuration = duration;

  const R = convertRemToPixels(3.5);
  const STROKE = convertRemToPixels(1);

  let ref: HTMLElement;
  let showPopup = timer.forceShowPopup;
  let numpadOpen = false;

  timer.forceShowPopup = false;

  const debouncedChangedCounters = debounce(() => {
    api.timerSet(timer.id, duration);
  }, 500);

  $: countdown = timer.duration * 60 - (timer.value || 0);
  $: if (duration !== prevDuration) {
    prevDuration = duration;
    debouncedChangedCounters();
  }

  onMount(() => {
    const closePopup = () => (showPopup = false);
    eventBus.on(Events.inputIdle, closePopup);
    return () => {
      eventBus.detach(Events.inputIdle, closePopup);
    };
  });

  onDestroy(debouncedChangedCounters.flush);

  // Handle commands from popup
  function onCommand(e: CustomEvent) {
    switch (e.detail) {
      case "STOP":
        api.timerStop(timer.id);
        showPopup = false;
        break;
      case "START":
        if (state !== "paused") api.timerStart(timer.id);
        else api.timerResume(timer.id);
        showPopup = false;
        break;
      case "PAUSE":
        api.timerPause(timer.id);
        break;
    }
  }

  // Close popup when focus is lost by clicking outside
  function onFocusOut(e: FocusEvent) {
    if (numpadOpen) return;
    if (ref.contains(e.relatedTarget as Node)) return;
    showPopup = false;
  }

  async function showNumpad() {
    numpadOpen = true;
    let r = await dialog.numpad($_("minutes"), duration, { min: 1, max: 999, digit: false, neg: false });
    if (r !== null && r !== duration) {
      r = clamp(r, 1, 999);
      duration = r;
    }
    numpadOpen = false;
  }

  function absorb(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }
</script>

<div
  bind:this={ref}
  class="countdown"
  class:active={state === "running"}
  class:popup-open={showPopup}
  style:--stroke={STROKE / 2 + "px"}
  on:pointerdown={absorb}
  on:pointerup={absorb}
  on:click|stopPropagation={() => (showPopup = !showPopup)}
  tabindex="-1"
  on:focusout|stopPropagation={onFocusOut}
>
  {#if showPopup}
    <CountdownEdit id={timer.id} bind:duration {state} on:command={onCommand} on:delete on:updated on:numpad={showNumpad} />
  {/if}
  <div class="circle-wrapper" class:flash={state == "running" && countdown < 60}>
    <svg width={R * 2} height={R * 2}>
      <circle cx={R} cy={R} r={R - STROKE / 2} />
      {#if state === "running" || state === "paused"}
        {@const pathLength = 500}
        <circle
          cx={R}
          cy={R}
          r={R - STROKE / 2}
          style:transition-duration={countdown === duration || countdown === 0 ? "0s" : "1s"}
          {pathLength}
          stroke-dasharray={pathLength}
          stroke-dashoffset={Math.round(pathLength - (pathLength * (countdown - 1)) / (duration * 60 - 1))}
        />
      {/if}
    </svg>
    {#if (state === "running" || state === "paused") && countdown > 0}
      <span class="center-element" style:opacity={state === "paused" ? 0.5 : 1} class:flash={state === "paused"}
        >{Math.floor(countdown / 60)}<br />{pad2(countdown % 60)}</span
      >
    {:else if state === "completed"}
      <span class="center-element">
        <CheckIcon color="green" height="46px" />
      </span>
    {:else}
      <span class="center-element">
        <PlayIcon height="3rem" />
      </span>
    {/if}
  </div>
  <div class="duration">{duration}m</div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  @keyframes blink {
    0% {
      opacity: 0.3;
    }
    49% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  .countdown {
    text-align: center;
    pointer-events: all;
    transition: opacity 500ms ease;
    position: relative;
    &:active:not(.popup-open) circle {
      fill: rgba(255, 255, 255, 0.3) !important;
    }

    > * {
      pointer-events: none;
    }

    .circle-wrapper {
      position: relative;
      opacity: 0.3;
      transition: opacity 500ms ease;

      > svg {
        transform: rotate(-90deg);

        circle {
          stroke: $primary-dimmed;
          stroke-width: var(--stroke);
          stroke-linecap: round;
          fill: transparent;
          transition: stroke 1s ease;

          &:nth-child(2) {
            //transition: stroke-dashoffset 1s linear;
            stroke: $company;
          }
        }
      }

      span {
        color: #fff;
        font-family: hmiFontBold;
        font-size: 2.2rem;
        line-height: 95%;
      }
    }

    .flash {
      animation: blink 300ms infinite alternate forwards;
    }

    &.active .circle-wrapper {
      opacity: 1;
    }

    .duration {
      margin-top: 3px;
      color: $primary-dimmed;
      font-family: hmiFont;
    }
  }
</style>
