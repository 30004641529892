<script lang="ts">
  import dialog from "@/dialog";
  import { numFmt } from "@/stores";
  import KeypadIcon from "@/svg/KeypadIcon.svelte";
  import MinusIcon from "@/svg/MinusIcon.svelte";
  import PlusIcon from "@/svg/PlusIcon.svelte";
  import { clamp } from "@/utils";
  import { debounce } from "lodash-es";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import IncDecButton from "./IncDecButton.svelte";

  export let label = "";
  export let description = "";
  export let value: number;
  export let width = "11rem";
  export let digit = false;
  export let negative = false;
  export let disabled = false;
  export let min: number = undefined;
  export let max: number = undefined;
  export let hidelabel = false;
  export let incdec = false;
  export let step = 1;
  export let nonumpad = false;

  let fire = createEventDispatcher();
  let dialogOpen = false;
  let showToolTip = false;
  let debouncedCloseTooltip = debounce(() => (showToolTip = false), 1000);
  let el: HTMLElement;
  let pos: "above" | "below" = "above";

  const options: string[] = [];
  if (digit) options.push("digit");
  if (negative) options.push("neg");

  onMount(() => {
    const observer = new ResizeObserver(onResize);
    observer.observe(el);
    return () => observer.disconnect();
  });

  onDestroy(() => {
    debouncedCloseTooltip.cancel();
  });

  async function edit() {
    if (nonumpad || disabled) return;
    dialogOpen = true;
    let r = await dialog.numpad(label || "Number", value, { info: description, min, max, digit, neg: negative });
    dialogOpen = false;
    if (r !== null) {
      if (min !== undefined) r = Math.max(r, min);
      if (max !== undefined) r = Math.min(r, max);
      value = r;
      el.dispatchEvent(new CustomEvent("change", { detail: r, bubbles: true }));
    }
  }

  const id = Math.random().toString(36).slice(2, 9);

  function deltaValue(delta: number) {
    let prevValue = value;

    let rv = Math.round(value / step) * step;
    rv = Math.round(rv * 100) / 100; // Round to 2 decimal places
    if (delta > 0 && rv <= prevValue) {
      value = rv + delta;
    } else if (delta < 0 && rv >= prevValue) {
      value = rv + delta;
    } else {
      value = rv;
    }
    value = clamp(value, min, max);
    value = Math.round(value * 100) / 100; // Round to 2 decimal places
    value += 0; // Prevent ugly -0 string
    if (value !== prevValue) {
      el.dispatchEvent(new CustomEvent("change", { detail: value, bubbles: true }));
    }

    showToolTip = true;
    debouncedCloseTooltip();
  }

  function onResize(entries: ResizeObserverEntry[]) {
    console.assert(entries.length === 1);
    let e = entries[0].target as HTMLElement;
    pos = e.offsetTop < 30 ? "below" : "above";
  }
</script>

<div class="number-input-container" bind:this={el} class:disabled on:change>
  {#if label && !hidelabel}
    <label for={id}>{label}:</label>
  {/if}
  <div tabindex="-1" {id} class="number-input" class:incdec class:nonumpad style:width on:click={edit} class:dialogOpen>
    <div class="value">{$numFmt(value, -4)}</div>
    {#if incdec && step}
      <IncDecButton on:click={() => deltaValue(-step)}><MinusIcon /></IncDecButton>
      <IncDecButton on:click={() => deltaValue(step)}><PlusIcon /></IncDecButton>
    {:else}
      <KeypadIcon height="65%" />
    {/if}

    {#if showToolTip && min !== undefined && max !== undefined}
      <div class="tooltip {pos}" transition:fade={{ duration: 200 }}>
        <span>Min: {$numFmt(min, -2)}&nbsp;&nbsp;|&nbsp;&nbsp;Max: {$numFmt(max, -2)}</span>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .number-input-container {
    --height: var(--controls-height);

    pointer-events: all;
    &.disabled {
      opacity: 0.5;
      :global(*) {
        pointer-events: none;
      }
    }

    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 2.6rem;
  }

  .number-input {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: var(--height);
    border-radius: calc(var(--height) / 8);
    transition: border 0.2s ease-in-out;
    background-color: var(--clr-input-field-background);
    padding-right: 0.25rem;
    min-width: max-content;

    &.incdec .value {
      text-align: center;
    }

    :first-child {
      flex-grow: 1;
    }
    :global(svg) {
      transition: color 0.2s ease-in-out;
      color: $primary-dimmed;
    }

    border: 2px solid $primary-dimmed;
    &.dialogOpen {
      border: 2px solid $company;
      :global(svg) {
        color: $company;
      }
    }

    &:not(.incdec) .value {
      padding-left: 1rem;
      padding-right: 0.3rem; // for keypad icon away from border
    }

    .value {
      min-width: 6rem;
      height: 100%;
      line-height: calc(var(--height) - 4px);
    }

    &:not(.nonumpad) .value:active {
      background-color: $primary-dimmed;
    }
  }

  .tooltip {
    pointer-events: none;
    isolation: isolate;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000000000000;

    color: black;
    background-color: $company;

    font-size: 1.6rem;
    font-family: hmiFont;
    white-space: nowrap;

    // border: 1px solid $primary-dimmed;
    border-radius: 0.5rem;

    padding: 0.5rem 1rem;

    box-shadow: 0 0 1rem black;

    &.above {
      bottom: calc(var(--height) + 0.5rem);
    }

    &.below {
      top: calc(var(--height) + 0.5rem);
    }
  }
</style>
