<script lang="ts">
  import hmi, { equipment, state } from "@/hmi";
  import AirTreatment from "@/lib/AirTreatment.svelte";
  import Led from "@/lib/Led.svelte";
  import SteamCycle from "@/lib/SteamCycle.svelte";
  import { numFmt, usesFarenheight } from "@/stores";
  import DefrostIcon from "@/svg/DefrostIcon.svelte";
  import Door from "@/svg/Door.svelte";
  import PadlockIcon from "@/svg/PadlockIcon.svelte";
  import { CtoF, NullFloat64Sentinel } from "@/utils";
  import { _ } from "svelte-i18n";
  import { fade } from "svelte/transition";

  export let showHumiditySetpoint = true;
  export let slideShown = true;

  const T = hmi.getValueStore("cur.T");
  const H = hmi.getValueStore("cur.H");
  const fanSpeed = hmi.getValueStore("cur.fan_speed");
  const coolingOn = hmi.getValueStore("cur.cooling_on");
  const coolingPause = hmi.getValueStore("sys.cooling_pause");
  const floorHeatingOn = hmi.getValueStore("cur.floor_heat");
  const doorClosed = hmi.getValueStore("cur.door_switch");
  const floorHeatingIntensity = hmi.getValueStore("sys.eff_floor_heat_intensity");
  const _effTSet = hmi.getValueStore("sys.eff_tset");
  const effHSet = hmi.getValueStore("sys.eff_hset");
  const defrostStatus = hmi.getObjectStore<DTO.DefrostStatus>("DefrostStatus");
  const steamExternal = hmi.getValueStore("cur.steam_external");

  $: effTSet = $usesFarenheight ? CtoF($_effTSet) : $_effTSet;
  $: hideSetpointH = $effHSet === null || !showHumiditySetpoint || $effHSet === NullFloat64Sentinel;
  $: hasAirHeatOption = $equipment?.includes("AIR_HEATING_ELEMENTS");
  $: hasSteamOption = $equipment?.includes("STEAM_RODS_IN_STEAM_POT");
  $: hasFloorHeatingOption = $equipment?.includes("FLOOR_HEATING");
  $: hasCoolingOption = $equipment?.includes("COOLING_PUMP_DOWN_SIGNAL");
</script>

<section>
  <div class="flex-center column">
    <div class="air-treatment-top">
      <div class="value align-top">
        {$numFmt($T, 1)}{$state.tempUnit}
        <span style:opacity={effTSet !== null ? 1 : 0}>{$numFmt(effTSet, -1)}{$state.tempUnit}</span>
      </div>
      {#if $equipment?.includes("HYGROMETER")}
        <div class="value align-top">
          {$numFmt($H, 1)}%
          <span style:opacity={hideSetpointH ? 0 : 1}>{$numFmt($effHSet, -1)}%</span>
        </div>
      {/if}
    </div>
    <AirTreatment />
    <div class="air-treatment-bottom">
      <div class="value">{$_("proofing.fan")}<span>{$numFmt($fanSpeed ?? 0, 0)}%</span></div>
      <div class="value" class:hide={!hasCoolingOption}>
        {$_("proofing.cooling")}<span style:color={$coolingOn ? "#2f81f7" : ""}>{$coolingOn ? "On" : "Off"}</span>
        {#if $coolingPause > 0}
          <span class="cooling-pause">
            <PadlockIcon height="1.8rem" />
            {$coolingPause}s
          </span>
        {/if}
        {#if $defrostStatus}
          <span class="cooling-pause">
            <DefrostIcon height="1.8rem" />
            {#if isFinite($defrostStatus.remain)}
              {$defrostStatus.remain}s
            {/if}
          </span>
        {/if}
      </div>
      <div class:hide={!hasSteamOption && !hasAirHeatOption}>
        <SteamCycle --font-size="3rem" canSteam={!hideSetpointH} disableAnimations={!slideShown} />
      </div>
      <div class="value" class:hide={!hasFloorHeatingOption}>
        {$_("proofing.floor_heating")}<span>
          <Led size="1rem" rgb="180,180,180" on={$floorHeatingOn !== 0} />
          {$numFmt($floorHeatingIntensity ?? 0, 0)}%
        </span>
      </div>
    </div>
    <h1>{$_("proofing.air_treatment")}</h1>

    {#if !$doorClosed}
      <div class="door" transition:fade={{ duration: 200 }}><Door /></div>
    {/if}

    {#if $steamExternal}
      <div class="ext_steam">EXT</div>
    {/if}
  </div>
</section>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  section {
    font-family: hmiFontBold;
    height: 100%;
    position: relative;
  }

  .flex-center {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 6rem;
    gap: 1rem;

    &.column {
      flex-direction: column;
    }

    h1 {
      position: absolute;
      bottom: 1rem;
      line-height: 1;
      font-family: hmiFont;
      color: $primary-dimmed;
      font-size: 3rem;
      white-space: nowrap;
    }
  }

  .air-treatment-top {
    position: absolute;
    display: grid;
    grid-template-columns: 10rem 10rem;
    top: 0;
    left: 14rem;

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
      font-family: hmiFont;
      span {
        font-family: hmiFontThin;
        font-size: 1.8rem;
        color: darken($primary, 30%);
      }
    }
  }

  .air-treatment-bottom {
    width: 100%;
    display: grid;
    padding-inline: 10rem;
    grid-template-columns: 10rem 10rem 1fr max-content;

    .value {
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-family: hmiFontThin;
      span {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-family: hmiFont;
        font-size: 3rem;
        transition: color 0.2s;
      }
    }
  }

  .door {
    color: #bebebe; // Set color of the door face
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    width: 4rem;
  }

  .cooling-pause {
    position: absolute;
    top: 8rem;
    display: flex;
    gap: 0.25rem !important;
    font-family: hmiFontThin !important;
    font-size: 1.8rem !important;
  }

  .hide {
    visibility: hidden;
  }

  .ext_steam {
    position: absolute;
    top: 3.5rem;
    right: 22rem;
    font-family: hmiFont;
    font-size: 2rem;
    color: $primary;
    padding: 0.5rem 1rem;
    background: $primary-dimmed;
    border-radius: 1rem;
  }
</style>
