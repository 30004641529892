<script lang="ts">
  import Dialog from "./Dialog.svelte";

  export let title: string;
  export let digits: number;

  let dlg: Dialog;
  let result: string | null = null;
  let inputString: string = "";
  let allowBackspace = false;

  function onClick(event: MouseEvent) {
    if (!event.target) return;

    let el = event.target as HTMLButtonElement;
    if (!el) return;
    if (el.tagName !== "BUTTON") return;
    let fn = el.getAttribute("data-fn");
    if (fn) {
      switch (fn) {
        case "del":
          if (inputString.length > 0) {
            inputString = inputString.slice(0, -1);
          }
          break;
      }
    } else {
      if (inputString.length >= digits) return;
      inputString += el.textContent;
    }

    if (inputString.length === 1) {
      allowBackspace = true;
    } else if (inputString.length === 0) allowBackspace = false;

    result = inputString.length === digits ? inputString : null;
    if (result) dlg.close(result);
  }
</script>

<Dialog bind:this={dlg} {title} returnValue={result}>
  <div class="numpad-body">
    <div class="digits center">
      {#each new Array(digits) as _, i}
        <div>{inputString?.length >= i + 1 ? "*" : ""}</div>
      {/each}
    </div>
    <div class="keys" on:click|preventDefault={onClick}>
      <button type="button">7</button>
      <button type="button">8</button>
      <button type="button">9</button>
      <button type="button" data-fn="del" disabled={!allowBackspace}> &#x232B </button>
      <button type="button">4</button>
      <button type="button">5</button>
      <button type="button">6</button>
      <button type="button" style="grid-column: 1">1</button>
      <button type="button">2</button>
      <button type="button">3</button>
      <button type="button">0</button>
    </div>
  </div>
</Dialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .numpad-body {
    text-align: center;
    overflow: hidden;
    padding: $dialog-padding;
  }

  .digits {
    display: flex;
    gap: var(--keys-gap);
    margin-top: 10px;
    margin-bottom: 20px;
    color: $primary;
    text-align: center;
    > div {
      font-size: 200%;
      border: 1px solid $company;
      height: var(--keys-size);
      width: var(--keys-size);
      line-height: var(--keys-size);
    }
  }

  .keys {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--keys-gap);
    * {
      text-align: center;
      min-width: var(--keys-size);
      min-height: var(--keys-size);
      padding: $dialog-padding;
      font-size: inherit;
    }
  }
</style>
