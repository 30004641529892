<script lang="ts">
  import Button from "@/lib/Button.svelte";
  import Switch from "@/lib/Switch.svelte";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import StorageSettings from "./_StorageSettings.svelte";
  import { hasUV, type AutoProgram } from "./auto";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: AutoProgram;
  export let storageProgramItems: ListItem[];

  const dispatch = createEventDispatcher();

  $: storage = data.storage1;

  function triggerReactivity() {
    storage = storage;
    dispatch("change");
  }
</script>

<div class="edit-container">
  <StorageSettings data={storage} {storageProgramItems} on:change={triggerReactivity} />

  <label>{$_("Light")}:</label>
  <Switch bind:checked={storage.light} on:change />

  {#if $hasUV}
    <label>{$_("UV")}:</label>
    <Switch bind:checked={storage.uv} on:change />
  {/if}

  <hr />

  <div class="whole-row flex-center pull-right">
    <Button size="small" disabled={!!data.storage2} on:click={() => dispatch("add")}>{$_("programs.auto.add_storage_phase")}</Button>
  </div>
</div>

<style lang="scss">
  .edit-container {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-content: start;
    align-items: center;
  }
</style>
