<script lang="ts">
  import hmi, { programs, state } from "@/hmi";
  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { Field } from "@/ui";
  import { _ } from "svelte-i18n";
  import { minTempForHumidity } from "./auto";

  export let data: { h?: number; t: number; program?: string };
  export let storageProgramItems: ListItem[];

  let programHasHumidity: boolean = false;
  let tempRange: [number, number] = [-20, 50];

  $: if (data.h === undefined) data.h = 80;
  $: {
    let program = $programs?.storage?.programs?.find((p) => p.slot === data.program);
    if (program) programHasHumidity = program.use_humidity;
    else {
      data.program = $programs?.storage?.programs?.[0]?.slot;
      programHasHumidity = false;
      data = data; // trigger reactivity
    }
    tempRange = hmi.getTempRangeForType(program?.type);
  }
</script>

{#if storageProgramItems.length > 1}
  <label>{$_("settings")}:</label>
  <ListInput items={storageProgramItems} bind:selectedValue={data.program} on:change width="26rem" />
{/if}

<label>{$_("Temperature")}:</label>
<div class="flex-line">
  <NumberInput
    label={$_("Temperature")}
    width="18rem"
    incdec
    step={1}
    min={tempRange[0]}
    max={tempRange[1]}
    bind:value={data.t}
    digit
    negative
    hidelabel
    on:change
  />
  <span>{$state.tempUnit}</span>
</div>

{#if programHasHumidity}
  <Field label={$_("Humidity")} disabled={data.t < $minTempForHumidity}>
    <div class="flex-line">
      <NumberInput label={$_("Humidity")} width="18rem" incdec step={1} min={0} max={100} bind:value={data.h} hidelabel on:change />
      <span>%</span>
    </div>
  </Field>
{/if}
