<script lang="ts">
  import hmi from "@/hmi";
  import SteamPotStatusPage from "@/lib/SteamPotStatusPage.svelte";
  import { autoProgram } from "@/stores";
  import { _ } from "svelte-i18n";
  import AirTreatmentPage from "../AirTreatmentPage.svelte";
  import Frame from "../Frame.svelte";
  import Swiper from "../Swiper.svelte";
  import TrendGraph from "../TrendGraph.svelte";
  import MainPage from "./MainPage.svelte";

  const message = hmi.getObjectStore<DTO.Prompt>("Message");
  const cycle = hmi.getObjectStore<DTO.AirCycleStatus>("AirCycleStatus");
</script>

<div class="swiper-pagination pagination-auto" />

<Frame icon="/img/menu-ikon-auto.svg" program={$autoProgram}>
  <!--
    WARNING:
    We might need to disable virtual slides because DateTimeInput goes into a infinite loop.
    FIXME: Find a solution to it in the future.
   -->
  <Swiper paginationClass="pagination-auto" let:curSlide>
    <swiper-slide class="page-padding" virtual>
      <MainPage />
    </swiper-slide>
    <swiper-slide>
      <AirTreatmentPage showHumiditySetpoint={$cycle?.cycleStart !== undefined && !$cycle?.cooling} slideShown={curSlide === 1} />
    </swiper-slide>
    <swiper-slide>
      <TrendGraph mode="auto" {curSlide} />
    </swiper-slide>
    <swiper-slide>
      <SteamPotStatusPage slideShown={curSlide === 3} />
    </swiper-slide>
  </Swiper>

  {#if $message}
    <div class="popup message">{$_($message.textId)}</div>
  {/if}
</Frame>
