<script lang="ts">
  import { api } from "@/api";
  import { DEFAULT_DEBOUNCED_SAVE_MS } from "@/hmi";
  import { usesFarenheight } from "@/stores";
  import { CtoF, FtoC, forFields } from "@/utils";
  import { debounce, isEqual } from "lodash-es";
  import { onDestroy } from "svelte";
  import { get } from "svelte/store";

  import NumberInput from "@/lib/NumberInput.svelte";
  import ConfigDialog from "../ConfigDialog.svelte";

  let data: any; // DTO.AlarmSettings;
  let lastSavedData: DTO.AlarmSettings;
  let changeCounter = 0;
  let debounceSave = debounce(save, DEFAULT_DEBOUNCED_SAVE_MS);

  onDestroy(() => {
    debounceSave.flush();
  });

  async function save() {
    if (!data) return;
    if (isEqual(data, lastSavedData)) return;
    let saveData = structuredClone(data);
    toServerTempUnit(saveData);
    let ok = await api.saveAlarmSettings(saveData);
    if (ok) lastSavedData = structuredClone(data);
  }

  function onRevert() {
    data = structuredClone(lastSavedData);
    changeCounter++;
  }

  function onChange() {
    debounceSave();
  }

  // Fields that maybe needs to be converted to/from °F.
  const tempFields = [
    /* none for now */
  ];

  // Convert (if needed) from server temperture unit (°C) to user selected unit (°F or °C)
  // The result is rounded to the nearest integer.
  export function toUserTempUnit(ap: DTO.AlarmSettings): void {
    if (!$usesFarenheight) return;
    forFields(ap, tempFields, (v: number) => Math.round(CtoF(v) * 10) / 10.0);
  }

  // Convert (if needed) from user temperture unit (°F or °C) to server unit (°C)
  // The result is rounded to the nearest integer.
  export function toServerTempUnit(ap: DTO.AlarmSettings): void {
    if (!get(usesFarenheight)) return;
    forFields(ap, tempFields, (v: number) => Math.round(FtoC(v) * 10) / 10.0);
  }

  api.loadAlarmSettings().then((p) => {
    data = p;
    if (!data) return;
    toUserTempUnit(data);
    lastSavedData = structuredClone(data);
    changeCounter++;
  });
</script>

<ConfigDialog title="Alarm settings">
  {#if data}
    <section>
      <h1>13 Water supply steam system</h1>
      <div class="rows">
        <label>Max filling cycles:</label>
        <NumberInput bind:value={data.steam_max_filling_cycles} incdec min={0} max={100} step={1} on:change={onChange} />
      </div>

      <h1>14 Steam system too weak</h1>
      <div class="rows">
        <label>Time:</label>
        <NumberInput bind:value={data.steam_weak_time} incdec min={0} max={360} step={1} on:change={onChange} />
        <span>min.</span>

        <label>Deviation:</label>
        <NumberInput bind:value={data.steam_weak_deviation} incdec min={0} max={50} step={1} on:change={onChange} />
        <span>%</span>
      </div>

      <h1>16 Emptying valve leaking</h1>
      <div class="rows">
        <label>Min. time between fillings:</label>
        <NumberInput bind:value={data.steam_min_time_between_fillings} incdec min={0} max={3600} step={1} on:change={onChange} />
        <span>s</span>
      </div>

      <h1>20 Cooling failure</h1>
      <div class="rows">
        <label>Max time cooling:</label>
        <NumberInput bind:value={data.cooling_max_time} incdec min={0} max={360} step={1} on:change={onChange} />
        <span>min.</span>

        <label>Temperature diff:</label>
        <NumberInput bind:value={data.cooling_max_temp_diff} incdec min={0} max={10} step={0.1} on:change={onChange} />
        <span>°C</span>
      </div>

      <h1>22 Compressor does not start</h1>
      <div class="rows">
        <label>Max delay compressor start:</label>
        <NumberInput bind:value={data.cooling_max_delay_compressor} incdec min={0} max={3600} step={1} on:change={onChange} />
        <span>s</span>
      </div>

      <h1>27 Compressor safety circuit</h1>
      <div class="rows">
        <label>Delay:</label>
        <NumberInput bind:value={data.cooling_compressor_safety_circuit} incdec min={0} max={60} step={1} on:change={onChange} />
        <span>s</span>
      </div>

      <h1>30 Heat failure</h1>
      <div class="rows">
        <label>Max time heating:</label>
        <NumberInput bind:value={data.heat_max_time} incdec min={0} max={360} step={1} on:change={onChange} />
        <span>min.</span>

        <label>Temperature diff:</label>
        <NumberInput bind:value={data.heat_max_temp_diff} incdec min={0} max={10} step={0.1} on:change={onChange} />
        <span>°C</span>
      </div>

      <h1>50 Defrost duration too long</h1>
      <div class="rows">
        <label>Max time defrost:</label>
        <NumberInput bind:value={data.defrost_max_time} incdec min={0} max={360} step={1} on:change={onChange} />
        <span>min.</span>
      </div>

      <h1>60 Door is open</h1>
      <div class="rows">
        <label>Max time door open:</label>
        <NumberInput bind:value={data.general_door_max_time} incdec min={0} max={60} step={1} on:change={onChange} />
        <span>min.</span>
      </div>
    </section>
  {/if}
</ConfigDialog>

<style lang="scss">
  section {
    padding: 2rem;
    max-height: 100%;
    overflow-y: scroll;
    font-size: 2.2rem;

    h1 {
      margin-bottom: 2rem;
    }

    label {
      min-width: 20rem;
    }

    .rows {
      margin-left: 8rem;
      margin-bottom: 2rem;
      padding: 1rem;
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 2rem;
      justify-content: start;
      align-items: center;
    }
  }
</style>
