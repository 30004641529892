<script lang="ts">
  import hmi, { client } from "@/hmi";
  import { _ } from "svelte-i18n";

  import dialog from "@/dialog";
  import Button from "@/lib/Button.svelte";
  import SteamPot from "@/lib/SteamPot.svelte";
  import SteampotDetails from "@/lib/SteampotDetails.svelte";
  import { numFmt } from "@/stores";
  import InfoIcon from "@/svg/InfoIcon.svelte";
  import PadlockIcon from "@/svg/PadlockIcon.svelte";
  import ToolsIcon from "@/svg/ToolsIcon.svelte";
  import Popup from "./Popup.svelte";

  export let slideShown = false;

  const amps = hmi.getValueStore("cur.steampot_amps");
  const initialized = hmi.getValueStore("sys.steampot_initialized");
  const steamPause = hmi.getValueStore("sys.steam_pause");
  const status = hmi.getObjectStore<DTO.SteampotStatus>("SteampotStatus");

  let statusText = "";
  let showSteampotDetails = false;
  let showSteampotDetailsSave = false;
  let showToolsPopup = false;

  // $: console.log("SteampotStatus:", $status);

  $: steampot_kwh = ($amps * 400 * 1 * 1.732) / 1000;
  $: {
    let a: string[] = [];
    if ($status) {
      if ($status.flags & (1 << 0)) a.push($_("$$steampot.cleaning"));
      if ($status.flags & (1 << 1)) a.push($_("$$steampot.rinsing"));
      if ($status.flags & (1 << 2)) a.push($_("$$steampot.emptying"));
      if ($status.flags & (1 << 3)) a.push($_("$$steampot.initializing"));
    }
    statusText = a.join(", ");
  }
  $: busy = $status && $status.flags !== 0;

  function doCleaning() {
    showToolsPopup = false;
    client.invoke("Run", "steamer.clean").catch(console.error);
  }

  function doEmptying() {
    showToolsPopup = false;
    client.invoke("Run", "steamer.empty").catch(console.error);
  }

  function doClearStats() {
    showToolsPopup = false;
    showSteampotDetails = false; // Hide the details view to force a reload of stats values.
    client.invoke("Run", "steamer.clearStats").catch(console.error);
  }

  function maybeDismissToolsPopup(e: FocusEvent) {
    document.querySelector(".tools-popup").contains(e.relatedTarget as Node) || (showToolsPopup = false);
  }

  async function confirm(msg: string, fn: Function) {
    if (await dialog.confirm(msg)) fn();
  }
</script>

<div class="steampot flex-center" style:opacity={!$status ? 0.3 : undefined}>
  <div>
    <div class="current">{$numFmt(steampot_kwh, 1)} kW<span>{$numFmt($amps, 1)} A</span></div>

    <!-- <hr />
    {#if $state !== 999}
      State: {$state} ({$stateTimer} s)
    {/if} -->

    <div class="text">
      {#if $status?.blocked}
        <div class="flex-line" style="gap: 0.25rem"><PadlockIcon height="2.4rem" /> {$status.remain}s</div>
      {/if}
      {#if statusText}
        <div>{$_("$$steampot.status")}: {statusText}</div>
        {#if $steamPause > 0}
          <div>{$_("$$steampot.time_remaning")}: {$steamPause}s</div>
        {/if}
      {/if}
    </div>
  </div>
  <SteamPot />
  <h1>{$_("proofing.steam")}</h1>
  <div class="tools">
    <Button
      naked
      on:pointerdown={() => {
        showSteampotDetailsSave = showSteampotDetails;
      }}
      on:click={() => (showSteampotDetails = !showSteampotDetailsSave)}
    >
      <InfoIcon height="4rem" color={showSteampotDetails ? "var(--clr-company)" : "#666"} />
    </Button>
    <Button naked on:click={() => (showToolsPopup = !showToolsPopup)} class="tools-popup-trigger">
      <ToolsIcon height="4rem" color={showToolsPopup ? "var(--clr-company)" : "#666"} />
    </Button>
  </div>
  {#if showSteampotDetails}
    <SteampotDetails smoothScroll={slideShown} on:dismiss={() => (showSteampotDetails = false)} />
  {/if}

  <Popup bind:show={showToolsPopup} left="1rem" bottom="1rem" trigger="tools-popup-trigger">
    <Button on:click={() => confirm($_("$$steampot.cleaning_confirm"), doCleaning)} disabled={busy}>{$_("$$steampot.cleaning")}</Button>
    <Button on:click={() => confirm($_("$$steampot.emptying_confirm"), doEmptying)} disabled={busy}>{$_("$$steampot.emptying")}</Button>
    <Button on:click={() => confirm($_("$$steampot.clear_stats_confirm"), doClearStats)}>{$_("$$steampot.clear_stats")}</Button>
  </Popup>
</div>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .steampot {
    gap: 8rem;
    font-family: hmiFont;

    .current {
      font-size: 5rem;
      text-align: center;
      min-width: 18rem;
      white-space: nowrap;
      span {
        display: block;
        font-size: 3rem;
        color: darken($primary, 30%);
      }
    }
    .text {
      position: absolute;
      left: 6rem;
      top: 3rem;
      font-size: 2rem;
      text-align: left;
      color: darken($primary, 30%);
    }
  }

  .flex-center {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 6rem;

    h1 {
      position: absolute;
      bottom: 1rem;
      line-height: 1;
      font-family: hmiFont;
      color: $primary-dimmed;
      font-size: 3rem;
    }
  }

  .tools {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }
</style>
