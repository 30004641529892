<script lang="ts">
  import hmi, { curHumidityText, curTempText, equipment, timers } from "@/hmi";
  import { Events, eventBus } from "@/utils";
  import { swipeable, type SwipeEventData } from "@react2svelte/swipeable";
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import AutoStartInfo from "./AutoStartInfo.svelte";
  import Frame from "./Frame.svelte";
  import StatusPanel from "./StatusPanel.svelte";

  let statusCollapsed = true;
  let logoUrl: string;

  let autoStartStatus = hmi.getObjectStore<DTO.AutoStartStatus>("AutoStartStatus");

  $: anyTimersNotStopped = $timers?.some((t) => t.state !== "stopped");
  $: anyTimersCompleted = $timers?.some((t) => t.state === "completed");
  $: if (anyTimersNotStopped || anyTimersCompleted) statusCollapsed = false;

  function onSwipeUp(e: CustomEvent<SwipeEventData>) {
    let initialY = e.detail.initial[1];
    if (initialY > 300) statusCollapsed = false;
  }

  function onSwipeDown(e: CustomEvent<SwipeEventData>) {
    let initialY = e.detail.initial[1];
    if (initialY > 300) statusCollapsed = true;
  }

  onMount(() => {
    const handler = (brand: string) => {
      switch (brand) {
        case "Revent":
          logoUrl = "/img/revent-big.png";
          break;
        case "Hein":
          logoUrl = "/img/hein-big.png";
          break;
        default:
          logoUrl = "/img/lillnord-big.png";
      }
    };
    eventBus.on(Events.brandChange, handler);
    handler(localStorage.getItem("brand") || "Lillnord");

    if ($autoStartStatus) statusCollapsed = false;

    return () => {
      eventBus.detach(Events.brandChange, handler);
    };
  });
</script>

<Frame title={$_("Standby")} icon="/img/menu-ikon-standby.svg">
  <div class="standby-page" use:swipeable on:swipedup={onSwipeUp} on:swipeddown={onSwipeDown}>
    <img src={logoUrl} draggable="false" />
    <div class="values">
      <div>
        <div style:text-transform="uppercase">{$_("off")}</div>
        <div>
          {$curTempText}
          {#if $equipment?.includes("HYGROMETER")}
            <div style="display: inline-block; transform: translateY(-2px)">|</div>
            {$curHumidityText}
          {/if}
        </div>
      </div>
    </div>
    <StatusPanel collapsable bind:collapsed={statusCollapsed}>
      <svelte:fragment slot="info">
        <AutoStartInfo />
      </svelte:fragment>
    </StatusPanel>
  </div>
</Frame>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .standby-page {
    height: 100%;
    font-family: hmiFontBold;

    display: grid;
    grid-template-rows: 1fr 1fr auto;
    place-items: center;

    img {
      max-height: 20rem; // Prevent logo from pushing down other stuff
    }

    .values {
      font-size: 5rem;
      width: 64rem;

      > div {
        border-radius: 35px;
        background-color: $company;
        color: $background;
        margin: 0 60px;
        padding: 0 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
</style>
