<script lang="ts">
  import CycleSleepIcon from "@/svg/CycleSleepIcon.svelte";
  import { convertRemToPixels } from "@/utils";
  import { onDestroy } from "svelte";

  export let size = "9rem";
  export let hideThumb = false;
  export let active: boolean | undefined = true;
  export let steamIsFirst: boolean | undefined = true;
  export let progress: number | undefined;
  export let steaming: number | undefined;
  export let heating: number | undefined;
  export let cooling: boolean | undefined;
  export let steamBusy: boolean | undefined;

  const cycleSeconds = 100;
  const pathLength = 1000;
  const strokeWidth = convertRemToPixels(1.3); // NOTE: If this is set to 12, a (bug?) in Chrome/Edge causes stroke on heat to end skewed
  const trackRadius = 100 - (strokeWidth + strokeWidth / 2 / 2);

  let posRef: SVGElement;
  let spinAnimation: Animation | null = null;

  onDestroy(() => {
    spinAnimation?.cancel();
  });

  $: if (posRef) {
    // We only get progress updates every 1s, so use the Web Animations API to animate the rotation
    // smoothly.
    //
    // HACH: To work around animation being out of sync on Firefox, we restart the animation at the
    // correct place every time the progress changes.
    // spinAnimation?.cancel();
    // if (active) {
    //   spinAnimation?.cancel();
    //   spinAnimation = posRef.animate([{ rotate: "1turn" }], {
    //     iterationStart: progress / cycleSeconds,
    //     duration: 1000 * cycleSeconds,
    //   });
    // }

    posRef.style.setProperty("--rotate", `${progress / cycleSeconds}turn`);
  }

  $: steamDashArray = steamIsFirst
    ? `${Math.round(steaming * 10)} ${pathLength}`
    : `0 ${pathLength - Math.round(steaming * 10)} ${pathLength}`;
  $: heatDashArray = steamIsFirst
    ? `0 ${pathLength - Math.round(heating * 10)} ${pathLength}`
    : `${Math.round(heating * 10)} ${pathLength}`;
</script>

<section style:--stroke-width={strokeWidth}>
  <svg width={size} height={size} viewBox="0 0 200 200">
    <g transform="rotate(-90)">
      <!-- Track -->
      <circle
        cx="100"
        cy="100"
        r={trackRadius}
        stroke={cooling ? "var(--clr-cooling)" : "var(--clr-track)"}
        stroke-width={strokeWidth / 2}
      />

      <!-- Steam -->
      {#if steaming !== undefined}
        <circle
          class:blink={steamBusy}
          cx="100"
          cy="100"
          r={trackRadius}
          stroke="var(--clr-steam)"
          {pathLength}
          stroke-dasharray={steamDashArray}
        />
      {/if}

      <!-- Heat -->
      {#if heating !== undefined}
        <circle
          r={trackRadius}
          stroke-width={strokeWidth / 2}
          cx="100"
          cy="100"
          stroke="var(--clr-heat)"
          {pathLength}
          stroke-dasharray={heatDashArray}
        />
      {/if}
    </g>

    <!-- Position -->
    {#if active && !hideThumb}
      <g bind:this={posRef} class="pos-thumb">
        <circle
          cx="100"
          cy={strokeWidth + strokeWidth / 2 / 2}
          r={strokeWidth}
          fill={"var(--clr-position)"}
          stroke="var(--clr-track)"
          stroke-width={strokeWidth / 4}
        />
      </g>
    {/if}
    {#if progress !== undefined}
      <text x="50%" y="50%" fill="white">{Math.floor(progress)}</text>
    {/if}
  </svg>

  {#if progress === undefined}
    <div class="sleeping"><CycleSleepIcon height="3rem" /></div>
  {/if}
</section>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  section {
    --clr-track: #{$primary-dimmed};
    --clr-position: #{$company};
    --clr-steam: hsl(0, 100%, 99%);
    --clr-heat: hsl(0, 70%, 50%);
    --clr-cooling: #{$primary-dimmed}; // hsl(240, 100%, 50%);

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 2rem;

    font-family: hmiFontThin;
    font-size: calc(var(--font-size, 4rem) * 0.75);
  }

  .sleeping {
    position: absolute;
    inset: auto;
    color: $primary-dimmed;
  }

  svg {
    font-size: 5rem;
    stroke-linecap: butt;
    fill: none;
    stroke-width: var(--stroke-width);

    g {
      transform-origin: center;
    }

    circle {
      transition: stroke-dasharray 0.3s ease;
    }

    text {
      dominant-baseline: central;
      text-anchor: middle;
      font-size: 4rem;
    }
  }

  .pos-thumb {
    transform: rotate(var(--rotate));
  }
</style>
