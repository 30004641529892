<script lang="ts">
  import ListInput from "@/lib/ListInput.svelte";
  import Switch from "@/lib/Switch.svelte";
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import StorageSettings from "./_StorageSettings.svelte";
  import { ProceedAction, hasUV, type AutoProgram } from "./auto";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: AutoProgram;
  export let storageProgramItems: ListItem[];

  const dispatch = createEventDispatcher();

  const proceedItems = [
    { text: $_("programs.auto_choice.open_door"), value: ProceedAction.OpenDoor },
    // { text: $_("programs.auto_choice.button_on_screen"), value: ProceedAction.ButtonOnScreen },
    { text: $_("programs.auto_choice.setpoint_reached"), value: ProceedAction.SetpointReached },
  ];

  $: preCooling = data.pre_cooling;

  function triggerReactivity() {
    preCooling = preCooling;
    dispatch("change");
  }
</script>

<div class="edit-container">
  <StorageSettings data={preCooling} {storageProgramItems} on:change={triggerReactivity} />

  <label>{$_("Light")}:</label>
  <Switch bind:checked={preCooling.light} on:change />

  {#if $hasUV}
    <label>{$_("UV")}:</label>
    <Switch bind:checked={preCooling.uv} on:change />
  {/if}

  <!-- FIXME: Re-introduce this later post v1.0 :AutoStart
  <CheckBox label={$_("autostart") + ":"} bind:checked={preCooling.auto_start} on:change={triggerReactivity} />
  <div class="flex-line" class:disabled={!preCooling.auto_start}>
    <TimeInput bind:value={preCooling.auto_start_time} on:change />
    <ClockIcon height="3rem" color="var(--clr-field-icon)" opacity={preCooling.auto_start ? 1 : 0.5} />
  </div>
  -->

  <hr />

  <label>{$_("proceed")}:</label>
  <ListInput items={proceedItems} width="100%" bind:selectedValue={preCooling.proceed.action} on:change />
</div>

<style lang="scss">
  .edit-container {
    --clr-inc-dec: gray;
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-content: start;
    align-items: center;
    min-height: 17.5rem;
  }

  /*   Re-introduce this later post v1.0 :AutoStart
  .disabled {
    :global(> *) {
      pointer-events: none;
      opacity: 0.3 !important;
    }
  }
  */
</style>
