<script lang="ts">
  import { api } from "@/api";
  import { fullDateTimeFmtNoSecs } from "@/stores";
  import { dateNow, getDayNames } from "@/utils";
  import { upperFirst } from "lodash-es";
  import { onDestroy } from "svelte";
  import { _, locale } from "svelte-i18n";
  import Button from "./Button.svelte";

  export let prompt: DTO.UIPrompt;

  const args = prompt?.args?.at(0) as { prg_slot: string; prg_name: string; baking_time: number; shortned: boolean };

  let countdownTimer: number | null = null;
  let countdown: number | null = 9;

  if (prompt.due) {
    countdown = Math.floor(prompt.due - dateNow().getTime() / 1000);
    countdownTimer = window.setInterval(timerCallbask, 1000);
  }

  function timerCallbask() {
    countdown--;
    if (countdown <= 0) {
      killTimer();
    }
  }

  function killTimer() {
    if (countdownTimer) {
      clearInterval(countdownTimer);
      countdownTimer = null;
      countdown = 0;
    }
  }

  onDestroy(killTimer);

  function sendUIPromptResult(result: any) {
    if (!prompt) return;
    api.postUIPromptResult(prompt.group, prompt.id, result);
  }
</script>

{#if prompt}
  <section class="popup prompt">
    <p class="bold first-upper">{$_("menu.program")} {args.prg_slot}: {args.prg_name}</p>
    <p>
      {$_("auto_mode.Baking")}:<br />{upperFirst(getDayNames($locale)[new Date(args.baking_time * 1000).getDay()])}
      {$fullDateTimeFmtNoSecs(args.baking_time * 1000)}
    </p>
    <div class="button-group center">
      <Button minWidth="16rem" on:click={() => sendUIPromptResult(-1)}>{$_("cancel")}</Button>
      <Button minWidth="16rem" on:click={() => sendUIPromptResult(0)}>{$_("start")} ({countdown + 1})</Button>
    </div>
  </section>
{/if}

<style lang="scss">
  section {
    font-size: 2.8rem;
  }

  p {
    text-wrap: nowrap;
  }
</style>
