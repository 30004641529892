<script lang="ts">
  import hmi, { programs, state } from "@/hmi";
  import DurationInput from "@/lib/DurationInput.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import Switch from "@/lib/Switch.svelte";
  import { numFmt } from "@/stores";
  import DurationIcon from "@/svg/DurationIcon.svelte";
  import { _ } from "svelte-i18n";
  import { Curve, curveNames, type AutoProgram } from "./auto";

  $$restProps; // Get rid of "<xxx> was created with unknown prop" warning

  export let data: AutoProgram;
  export let calculatedTimeString: string;
  export let proofingProgramItems: ListItem[];

  const curves = curveNames.map((n, i) => ({ text: n, value: i }));

  let tempRange: [number, number] = [-20, 50];

  $: thawing = data.thawing;
  $: useFactor = thawing.curve === Curve.adapThawClassic || thawing.curve === Curve.adapThawLinear;

  $: if (thawing) {
    let p = $programs?.proofing.programs.find((p) => p.slot === thawing.program);
    tempRange = hmi.getTempRangeForType(p?.type);
  }

  function calcKelvinPerHour(p: AutoProgram) {
    let startTemp = p.storage2?.t ?? p.storage1.t;
    let endTemp = p.proofing.t;
    return (endTemp - startTemp) / (p.thawing.duration / 3600);
  }
</script>

<div class="edit-container">
  <div class="column">
    <label>{$_("Humidity")}:</label>
    <div class="flex-line">
      <NumberInput label={$_("Humidity")} width="18rem" incdec step={1} min={0} max={100} bind:value={thawing.h} hidelabel on:change />
      <span>%</span>
    </div>

    <label>{$_("Curve")}:</label>
    <ListInput width="26rem" items={curves} bind:selectedValue={thawing.curve} on:change />

    {#if useFactor}
      <label>{$_("factor")}:</label>
      <div class="flex-line">
        <NumberInput
          label={$_("factor")}
          width="18rem"
          incdec
          digit
          step={0.1}
          min={thawing.curve === Curve.adapThawLinear ? 0.1 : 0.5}
          max={thawing.curve === Curve.adapThawLinear ? 100 : 20}
          bind:value={thawing.factor}
          hidelabel
          on:change
        />
        {#if thawing.curve === Curve.adapThawLinear}
          <span>°/h</span>
        {/if}
      </div>
    {/if}

    {#if useFactor}
      <label />
      <div class="dim flex-line">
        {calculatedTimeString}
        <DurationIcon height="3rem" color="var(--clr-field-icon)" />
      </div>
    {/if}

    {#if thawing.curve === Curve.adapThawTime}
      <label>{$_("duration")}:</label>
      <div class="flex-line">
        <DurationInput bind:value={thawing.duration} on:change />
        <DurationIcon height="3rem" color="var(--clr-field-icon)" />
        <span class="dim">{$numFmt(calcKelvinPerHour(data), -1)} °/h</span>
      </div>
    {/if}

    {#if proofingProgramItems.length > 1}
      <label>{$_("settings")}:</label>
      <ListInput items={proofingProgramItems} bind:selectedValue={thawing.program} on:change width="100%" />
    {/if}

    <label>{$_("programs.auto.steam_from")}:</label>
    <div class="flex-line">
      <NumberInput
        label={$_("programs.auto.steam_from")}
        width="18rem"
        incdec
        step={1}
        min={tempRange[0]}
        max={tempRange[1]}
        bind:value={thawing.add_steam_above}
        hidelabel
        on:change
      />
      <span>{$state.tempUnit}</span>
    </div>

    <label>{$_("Light")}:</label>
    <Switch bind:checked={thawing.light} on:change />
  </div>
</div>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  .edit-container {
    position: relative;
    display: flex;
    gap: 2rem;

    label {
      white-space: nowrap;
    }

    .column {
      position: relative;
      height: 100%;
      display: grid;
      grid-template-columns: auto auto;
      gap: 2rem;
      align-content: start;
      align-items: center;
    }
  }
</style>
