<script lang="ts">
  import { client, DEFAULT_DEBOUNCED_SAVE_MS } from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import Led from "@/lib/Led.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { debounce } from "lodash-es";
  import { onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import ConfigDialog from "./ConfigDialog.svelte";

  let netInfo: { ip: string; mask: string; dns: string; gateway: string; mac: string };

  const delayedSave = debounce(() => {
    client.invoke("Save", "modbus-server", data).catch((err) => {
      console.error(err);
    });
  }, DEFAULT_DEBOUNCED_SAVE_MS);

  let data: DTO.ModbusServerConfig;

  const baudrates = [
    { value: 1200, text: "1200" },
    { value: 2400, text: "2400" },
    { value: 4800, text: "4800" },
    { value: 9600, text: "9600" },
    { value: 19200, text: "19200" },
    { value: 38400, text: "38400" },
    { value: 57600, text: "57600" },
    { value: 115200, text: "115200" },
  ];

  function updateNetInfo() {
    client
      .invoke<any>("GetNetworkInfo", "eth0")
      .then((info) => {
        netInfo = info;
      })
      .catch((err) => {
        console.error(err);
        netInfo = undefined;
      });
  }

  onMount(() => {
    client
      .invoke<DTO.ModbusServerConfig>("Load", "modbus-server")
      .then((d) => {
        console.log(d);
        data = d;
      })
      .catch((err) => {
        console.error(err);
      });

    updateNetInfo();
    let interval = setInterval(updateNetInfo, 2000);
    return () => clearInterval(interval);
  });

  onDestroy(() => {
    delayedSave.flush();
  });

  function onChange() {
    delayedSave();
  }
</script>

<ConfigDialog title={$_("$$modbus_slave.@title")} helpId="MODBUS_SLAVE">
  {#if data}
    <div class="content">
      <div class="ports">
        <br />
        <NumberInput
          label={$_("$$modbus_slave.slave_id")}
          bind:value={data.slave_id}
          on:change={onChange}
          min={1}
          max={247}
          width="auto"
          incdec
        />
        <br />
        <div class="flex-line">
          <CheckBox bind:checked={data.enable_tcp} label="TCP" on:change={onChange} />
          <NumberInput
            label={$_("$$modbus_slave.port")}
            bind:value={data.tcp_port}
            on:change={onChange}
            disabled={!data.enable_tcp}
            min={100}
            max={0xffff}
            width="auto"
            incdec
          />
        </div>
        <div class="ip-info flex-line" style:gap="1rem">
          <Led on rgb={netInfo?.ip ? "50, 205, 50" : "205, 0, 0"} />
          {$_("$$modbus_slave.lan_status")}
        </div>
        {#if netInfo}
          <div class="ip-info">
            IP: {netInfo.ip || "-"} &nbsp; MAC: {netInfo.mac}
          </div>
        {/if}
        <br />
        <div class="flex-line">
          <CheckBox bind:checked={data.enable_rs232} label="RS232" on:change={onChange} />
          <ListInput
            items={baudrates}
            label={$_("$$modbus_slave.baudrate")}
            bind:selectedValue={data.rs232_baudrate}
            on:change={onChange}
            disabled={!data.enable_rs232}
          />
        </div>
        <div class="flex-line">
          <CheckBox bind:checked={data.enable_rs485} label="RS485 Port 2" on:change={onChange} />
          <ListInput
            items={baudrates}
            label={$_("$$modbus_slave.baudrate")}
            bind:selectedValue={data.rs485_baudrate}
            on:change={onChange}
            disabled={!data.enable_rs485}
          />
        </div>
      </div>
    </div>
  {/if}
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .content {
    padding: $dialog-padding;
    height: 100%;
    font-family: hmiFont;
    font-size: 2.4rem;

    display: grid;
    grid-template-rows: 1fr auto;

    justify-content: center;
  }

  .ports {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .ip-info {
    margin-left: 1rem;
    font-size: 1.8rem;
    color: #ccc;
  }

  .flex-line {
    gap: 2rem;
  }
</style>
