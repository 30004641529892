<script lang="ts">
  import { AutoStartFunc } from "@/constants";
  import Dialog from "@/dialog/Dialog.svelte";
  import hmi, { programs, state } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import CheckBox from "@/lib/CheckBox.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import TimeInput from "@/lib/TimeInput.svelte";
  import { getDayNames } from "@/utils";
  import { upperFirst } from "lodash-es";
  import { onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import { MAX_TEMP_C, MIN_TEMP_C } from "./auto/auto";

  export let result: boolean | null = null;
  export let entry: DTO.AutoStartEntry;

  let tempRange: [number, number] = [MIN_TEMP_C, MAX_TEMP_C];

  const funcItems = [
    { value: AutoStartFunc.Standby, text: $_("Standby") },
    { value: AutoStartFunc.Proofing, text: $_("Proofing") },
    { value: AutoStartFunc.Storage, text: $_("Storage") },
    // { value: AutoStartFunc.Auto, text: $_("Auto") }, // Unavailable for now
  ];

  const dayItems = getDayNames($locale).map((d, i) => {
    return { text: upperFirst(d), value: i };
  });

  let dlg: Dialog;
  let programItems: ListItem[] = undefined;

  $: proofingPrograms = $programs.proofing.programs
    .filter((p) => p.use_in_manual)
    .map((p) => {
      return { text: `${p.slot}: ${p.name}`, value: p.slot };
    });
  $: storagePrograms = $programs.storage.programs
    .filter((p) => p.use_in_manual)
    .map((p) => {
      return { text: `${p.slot}: ${p.name}`, value: p.slot };
    });
  $: autoPrograms = $programs.auto.programs.map((p) => {
    return { text: `${p.slot}: ${p.name}`, value: p.slot };
  });

  onMount(onFuncChange);

  function onFuncChange() {
    if (entry.func === AutoStartFunc.Proofing) {
      programItems = proofingPrograms;
      if (entry.heatBoost === undefined) {
        entry.heatBoost = false;
      }
      if (entry.tset === undefined) {
        entry.tset = 32;
      }
      if (entry.hset === undefined) {
        entry.hset = 82;
      }
      queueMicrotask(() => {
        // Run later to ensure that the program is already set
        tempRange = hmi.getTempRangeForType($programs.proofing.programs?.find((p) => p.slot === entry.program)?.type);
      });
    } else if (entry.func === AutoStartFunc.Storage) {
      programItems = storagePrograms;
      if (entry.tset === undefined) {
        entry.tset = 32;
      }
      queueMicrotask(() => {
        // Run later to ensure that the program is already set
        tempRange = hmi.getTempRangeForType($programs.storage.programs?.find((p) => p.slot === entry.program)?.type);
      });
    } else if (entry.func === AutoStartFunc.Auto) {
      programItems = autoPrograms;
      if (entry.bakingTime === undefined) {
        entry.bakingTime = 3 * 60 * 60; // 03:00
      }
    } else {
      programItems = undefined;
    }

    // Ensure that the selected program is valid
    if (programItems) {
      if (!programItems.find((p) => p.value === entry.program)) {
        entry.program = programItems.length > 0 ? programItems[0].value : "";
      }
    } else {
      entry.program = "";
    }
  }
</script>

<Dialog title="Auto Start Entry" returnValue={result} bind:this={dlg}>
  <div class="content">
    <label>{$_("time")}:</label>
    <div class="flex-line">
      <ListInput items={dayItems} bind:selectedValue={entry.day} width="20rem" />
      <TimeInput bind:value={entry.startTime} />
    </div>

    <label>{$_("function")}:</label>
    <ListInput items={funcItems} bind:selectedValue={entry.func} width="100%" on:change={onFuncChange} />

    {#if programItems}
      <label>{$_("program")}:</label>
      <ListInput items={programItems} bind:selectedValue={entry.program} width="100%" />
    {/if}

    {#if entry.func === AutoStartFunc.Auto}
      <label>{$_("baking time")}:</label>
      <TimeInput bind:value={entry.bakingTime} />
    {/if}

    {#if entry.func === AutoStartFunc.Proofing || entry.func === AutoStartFunc.Storage}
      <label>{$_("Temperature")}:</label>
      <div class="flex-line">
        <NumberInput bind:value={entry.tset} min={tempRange[0]} max={tempRange[1]} step={1} incdec />
        <span>{$state.tempUnit}</span>
      </div>

      <label>{$_("Humidity")}:</label>
      <div class="flex-line">
        <NumberInput bind:value={entry.hset} min={40} max={100} step={1} incdec />
        <span>%</span>
      </div>

      {#if entry.func === AutoStartFunc.Proofing}
        <label></label>
        <CheckBox bind:checked={entry.heatBoost} label="Heat Boost" />
      {/if}
    {/if}
  </div>
  <div slot="actions" class="button-group space-between">
    <Button on:click={() => dlg.close(false)}>{$_("cancel")}</Button>
    <Button on:click={() => dlg.close(true)}>{$_("ok")}</Button>
  </div>
</Dialog>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .content {
    padding: $dialog-padding;
    font-family: hmiFont;

    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    align-items: center;
  }
</style>
