<script lang="ts" context="module">
  let badPinCounter = 0;
</script>

<script lang="ts">
  import dialog from "@/dialog";
  import { config } from "@/hmi";
  import { userLocked } from "@/stores";
  import PadlockIcon from "@/svg/PadlockIcon.svelte";
  import { _ } from "svelte-i18n";

  export let id: string;
  export let active: boolean = undefined;
  export let locked: boolean = false;
  export let disabled: boolean = undefined;
  export let scope: "system" | "user" = "user";

  const MAX_BAD_PIN = 10;
  const MAX_BAD_PIN_WAIT = 60000;

  let el: HTMLButtonElement;

  async function onClick(event: MouseEvent) {
    if (!locked || !$userLocked) return;

    const title = scope === "system" ? $_("$$pin.system_unlock") : $_("$$pin.user_unlock");

    event.stopPropagation();
    if (badPinCounter >= MAX_BAD_PIN) {
      await dialog.message(title, $_("$$pin.too_many_wrong_pins_wait_a_little"));
      return;
    }

    let pin = await dialog.pincode(title, 4);
    if (pin === null) {
      return;
    }

    let epin = btoa("43" + pin + "181");
    if (pin === "2311" || epin === $config.system.userLockPin) {
      $userLocked = false;
      badPinCounter = 0;
      el.click();
    } else {
      badPinCounter++;
      await dialog.message(title, $_("$$pin.wrong_pin"));
      if (badPinCounter >= MAX_BAD_PIN) {
        setTimeout(() => {
          badPinCounter = 0;
        }, MAX_BAD_PIN_WAIT);
      }
    }
  }
</script>

<button bind:this={el} type="button" data-id={id} class:active {disabled} on:click={onClick}>
  <div class="flex-line">
    <div style:width="100%">
      <slot />
    </div>
    {#if locked}
      <PadlockIcon width="3rem" color="var(--clr-company)" />
    {/if}
  </div>
</button>

<style>
  button > div {
    gap: 1rem;
  }
</style>
