<script lang="ts">
  import appConfig from "@/app-config.json";
  import dialog from "@/dialog";
  import hmi, { config, config as hmiConfig } from "@/hmi";
  import Menu from "@/menu/Menu.svelte";
  import menuHandler from "@/menu/handler";
  import ModeViewSelector from "@/modes/ModeViewSelector.svelte";
  import { clickDelay, fullScreenComponent, showClickProgress, showDebugConsole, userLocked } from "@/stores";
  import { Events, eventBus } from "@/utils";
  import { debounce, type DebouncedFunc } from "lodash-es";
  import { onDestroy, setContext } from "svelte";
  import { addMessages, getLocaleFromNavigator, init, locale, locales, register } from "svelte-i18n";
  import { writable } from "svelte/store";
  import { register as registerSwiper } from "swiper/element";
  import "uplot/dist/uPlot.min.css";
  import LockScreen from "./LockScreen.svelte";
  import DebugConsole from "./lib/DebugConsole.svelte";
  import UIPrompt from "./lib/UIPrompt.svelte";

  const USER_RELOCK_TIMEOUT = 1000 * 60 * 10; // 10 minutes

  registerSwiper();

  init({
    fallbackLocale: "en-GB",
    initialLocale: "en-GB",
    loadingDelay: 200,
    warnOnMissingMessages: true,
  });

  let background = writable<string | null>(null);
  let locked = false;
  let promptOpen = false;

  const rootStyle = document.querySelector<HTMLBaseElement>(":root")?.style;
  const s_config = writable<DTO.Config | undefined>(undefined);
  const s_mode = hmi.getValueStore("set.MODE");
  const s_light = hmi.getValueStore("set.LIGHT");

  let userIdleDebounce: DebouncedFunc<() => void>;
  let lockTimeoutDebounce: DebouncedFunc<() => void>;

  let userLockTimeoutDebounce = debounce(() => {
    if ($config?.system.userLockPin) {
      $userLocked = true;
    }
  }, USER_RELOCK_TIMEOUT);

  /*
  // This is a hack to make window fit on a high DPI tablet
  // FIXME: This should probably be removed at some point!
  hmi.log(`dpi=${window.devicePixelRatio}, w=${window.innerWidth}, h=${window.innerHeight}`);
  if (window.devicePixelRatio >= 2) {
    (<any>document.body.style).zoom = "80%";
  }
  */

  // FIXME: Remove demo config at some point
  $: if ($hmiConfig) {
    let cfg = { ...appConfig, ...$hmiConfig };
    s_config.set(cfg);
    onNewConfig(cfg);
  }

  $: if ($config?.system) createTimeoutDetectors();

  if (window.parent !== window) document.body.classList.add("iframe");

  function createTimeoutDetectors() {
    const resetUIState = () => {
      if (localStorage.getItem("dev.ignoreIdleInput") != "true") {
        eventBus.emit(Events.inputIdle);
        dialog.closeAll();
      }
    };

    const handleIdleTimeout = () => {
      if ($fullScreenComponent || (window.location.hostname !== "localhost" && localStorage.getItem("screen_ignore") === "true")) return;
      resetUIState();
    };

    const handleLockTimeout = () => {
      if ($fullScreenComponent || (window.location.hostname !== "localhost" && localStorage.getItem("screen_ignore") === "true")) return;
      resetUIState();
      locked = true;
    };

    userIdleDebounce?.cancel();
    userIdleDebounce = undefined;
    if ($config.system.userIdleTimeout) {
      let t = Math.max(10, $config.system.userIdleTimeout);
      userIdleDebounce = debounce(handleIdleTimeout, t * 1000);
      userIdleDebounce();
      console.log(`User idle timeout set to ${t} seconds`);
    }

    lockTimeoutDebounce?.cancel();
    lockTimeoutDebounce = undefined;
    if ($config.system.lockTimeout) {
      let t = Math.max(10, $config.system.lockTimeout);
      lockTimeoutDebounce = debounce(handleLockTimeout, t * 1000);
      lockTimeoutDebounce();
      console.log(`Lock timeout set to ${t} seconds`);
    }
  }

  // if (localStorage.getItem("dev.noSlowButtons") == "true") {
  //   appConfig.mode_switch_click_duration_ms = 0;
  // }

  register("zh-CN", () => import("./i18n/zh-CN.json"));
  register("da-DK", () => import("./i18n/da-DK.json"));
  register("nb-NO", () => import("./i18n/no.json"));
  register("de-DE", () => import("./i18n/de.json"));
  register("nl", () => import("./i18n/nl.json"));
  register("en-GB", () => import("./i18n/en.json"));
  register("en-US", () => import("./i18n/en.json"));
  addMessages("en-US", { use_farenheight_temps: "true" });
  register("es", () => import("./i18n/es.json"));
  register("pl", () => import("./i18n/pl.json"));
  register("ja", () => import("./i18n/ja.json"));
  // addMessages("DEV", { dummy: "123" }); // FIXME: Hide this in production build
  selectLocale();

  setContext<AppContext>("app", {
    config: s_config,
    background,
    subTitle: writable(""),
  });

  onDestroy(() => {
    lockTimeoutDebounce?.cancel();
    userIdleDebounce?.cancel();
  });

  function onNewConfig(newConfig: DTO.Config | null) {
    if (!newConfig) return;

    /*
    const style = document.querySelector<HTMLBaseElement>(":root")?.style;
    if (!style) return;

    const theme = newConfig?.theme;
    if (theme) {
      style.setProperty("--hmi-background-opacity", theme["background_opacity"]);
      style.setProperty("--primary", theme["primary_color"]);
      style.setProperty("--primary-inverse", theme["primary_inverse_color"]);
      style.setProperty("--secondary-color", theme["secondary_color"]);
      style.setProperty("--secondary-color-inverse", theme["secondary_inverse_color"]);
      style.setProperty("--active-link", theme["secondary_color"]); // FIXME: Rename css variable
    }
    */
  }

  // Save user selected locale in local storage if it changes
  $: if ($locale) localStorage.setItem("hmi.locale", $locale);

  // Show i18n string IDs in DEV mode
  // FIXME: Don't do this in production build
  $: if ($locale === "DEV") {
    console.log("I18N DEV MODE ENABLED");
    document.body.classList.add("i18n-id-reveal");
  } else {
    document.body.classList.remove("i18n-id-reveal");
  }

  // Select a locale based on user selection or browser locale
  function selectLocale() {
    // Get user selected locale or fallback to browser locale
    let userLocale = localStorage.getItem("hmi.locale") || getLocaleFromNavigator();
    // 1st try perfect match
    let match = $locales.find((v) => v === userLocale);
    if (!match) {
      // 2nd try matching the start
      match = $locales.find((v) => v.startsWith(userLocale!));
      if (match) {
        console.log("Found partial locale match:", userLocale, "=>", match);
      }
    } else {
      console.log("Found perfect locale match:", match);
    }
    if (!match) {
      console.log("Found no locale match. Using en-GB as fallback");
      match = "en-GB";
    }
    locale.set(match);
  }

  function onMenuSelect(e: CustomEvent<string>) {
    hmi.log(`Menu select: ${e.detail}`);
    menuHandler(e.detail);
  }

  function registerPointerAction(e: MouseEvent) {
    // Store last clicked position so we can animate windows from that location
    const body_style = document.body.style;
    body_style.setProperty("--click-point-x", Math.round(e.clientX) + "px");
    body_style.setProperty("--click-point-y", Math.round(e.clientY) + "px");

    if (userIdleDebounce) userIdleDebounce();
    if (lockTimeoutDebounce) lockTimeoutDebounce();
    userLockTimeoutDebounce();
  }

  function onTouchStart(e: TouchEvent) {
    if (userIdleDebounce) userIdleDebounce();
    if (lockTimeoutDebounce) lockTimeoutDebounce();
    userLockTimeoutDebounce();
  }

  // Show error & unhandledrejection event in server log
  window.addEventListener("error", (e) => {
    hmi.log(`ERROR\n${e.filename}@${e.lineno}: ${e.message}`);
  });
  window.addEventListener("unhandledrejection", (e) => {
    hmi.log(`ERROR:\n${e.reason.stack.split("\n").slice(0, 2).join("\n")}`);
  });

  /**
   * Handles the key press event on the document.
   * If the Ctrl key and F12 key are pressed simultaneously, it toggles the debug console.
   * @param {KeyboardEvent} e - The keyboard event object.
   */
  function onDocumentKeyUp(e: KeyboardEvent) {
    // :DebugConsole
    if (e.ctrlKey && e.key === "F12") {
      $showDebugConsole = !$showDebugConsole;
    }
  }

  function onDocumentContextMenu(e: MouseEvent) {
    e.preventDefault();
    e.target?.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true }));
  }
</script>

<svelte:head>
  <link rel="preload" href="/fonts/Roboto-Bold.woff2" as="font" type="font/woff2" crossorigin="anonymous" />
  <link rel="preload" href="/fonts/Roboto-Regular.woff2" as="font" type="font/woff2" crossorigin="anonymous" />
  <link rel="preload" href="/fonts/Roboto-Thin.woff2" as="font" type="font/woff2" crossorigin="anonymous" />
</svelte:head>

<svelte:document on:keyup={onDocumentKeyUp} on:contextmenu|preventDefault={onDocumentContextMenu} />

<!-- Capture global clicks for idle detection. Prevent browser context menu from showing -->
<svelte:body
  on:pointerdown|capture|passive={registerPointerAction}
  on:pointerup|capture|passive={registerPointerAction}
  on:touchstart|capture|passive={onTouchStart}
/>

<main>
  {#if $showClickProgress && $clickDelay > 0}
    <div
      class="click-progress-bar"
      style:--slowClickDuration="{$clickDelay}ms"
      on:animationend={() => eventBus.emit(Events.clickProgressCompleted)}
    />
  {/if}

  <div class="content" class:lights-on={$s_light && !$fullScreenComponent} tabindex="-1">
    {#if $fullScreenComponent}
      <div class="full-screen-component">
        <svelte:component this={$fullScreenComponent} />
      </div>
    {:else}
      <div class:locked={promptOpen}>
        <ModeViewSelector mode={$s_mode} />
      </div>
      <UIPrompt bind:promptOpen />
    {/if}
  </div>

  <!-- FIXME: Remove this when the debug is no longer needed :DebugConsole  -->
  {#if $showDebugConsole}
    <DebugConsole on:close={() => ($showDebugConsole = false)} />
  {/if}

  {#if !$fullScreenComponent}
    <Menu bind:mode={$s_mode} on:menu-select={onMenuSelect} {locked} />
    {#if locked}
      <LockScreen bind:locked />
    {/if}
  {/if}
</main>

<style lang="scss">
  @use "./styles/variables.scss" as *;

  @keyframes click-progress {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  // click-progress-bar shows the progress when clicking a mode button
  .click-progress-bar {
    animation: click-progress var(--slowClickDuration) linear forwards;
    background-color: $company;
    position: absolute;
    z-index: 9999999;
    pointer-events: none;
    width: 0;
    left: 0;
    top: 0;
    height: 0.3rem;
  }

  .content {
    --light-color: white;

    &::after {
      content: "";
      position: absolute;
      overflow: hidden;
      top: 0;
      opacity: 0;
      inset-inline: auto;
      width: 100%;
      height: 100%;
      // transform: translateY(-20rem);
      // background: radial-gradient(25rem at top, var(--light-color) 50%, transparent);
      // filter: blur(4rem);
      transform: scaleX(1.5);
      background: url(/img/halo-big.png) no-repeat;
      background-position: center 0px;
      // transition: opacity var(--animation-duration) ease-in-out;
      pointer-events: none;
    }

    &.lights-on::after {
      opacity: 0.5;
    }
  }

  .locked {
    :global(*) {
      pointer-events: none !important;
    }
  }

  .full-screen-component {
    display: grid;
    place-content: center;
    height: 100%;
    width: calc(100% + var(--menu-width)); // Escape our box and use the menu space as well
  }
</style>
